import React,{ useState, useEffect,Fragment,useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Select from '../form/Select';
import Textarea from '../form/Textarea';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

import { postData } from '../../services/api';
import toastr from 'toastr';
import { useTranslation } from "react-i18next";
import { monthYear } from '../../helpers';

const ExpensesPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
  message,
  heading,
}) => {   
  const { i18n,t } = useTranslation();
  const flatPicker = useRef(null);
  const role = localStorage.getItem("role");  
  const [id,setId] = useState('');
  const [type,setType] = useState('');
  const [date,setDate] = useState('');
  const [amount,setAmount] = useState('');
  const [comments,setComments] = useState('');
  const [monthOfExpense,setMonthOfExpense] = useState('');
  const [loading,setLoading] = useState(false);
  const [expenseTypes,setExpenseTypes] = useState([]);  

  const closeModal = () =>{ setIsOpen(false) } 
  
  const onSubmit = async () => {
      try {
        setLoading(true);
        let path ='';
        let payload = {};
        if(id){
          path = "expenses/update";
          payload =  {
            _id : id,
            type : (type !== "" )?type:null,
            date : date,
            amount:amount,
            comments : (comments !== "" )?comments:null,
            month_of_expense : monthOfExpense
          }
        }else{
          path = "expenses/create";
          payload =  {
            type : (type !== "" )?type:null,
            date : date,
            amount:amount,
            comments : (comments !== "" )?comments:null,
            month_of_expense : monthOfExpense
          }
        }
        const result = await postData(path, payload);
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          getActionValue({ button_type : 'store',row_id : data?._id,current_status : ''});
          setIsOpen(false)
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setLoading(false);
      }
  }

  useEffect(() => {
    async function expensesTypeData() {
      try {
        const result = await postData("filter-item/list", {
            sortQuery:{'created_at':1},
            status:1,
            type : "expense-type"
        });
        if (result.data) {
          setExpenseTypes(result.data);
        } else {
            console.log('Position list message',result.message)
        }
      } catch (error) {
      console.log('Error position list catch',error.message)
      }
    }
    expensesTypeData();
  },[])  

  useEffect(()=>{
    setId(data?._id);
    setType(data?.type);
    setDate(data?.date);
    setAmount(data?.amount);
    setComments(data?.comments);
  },[data])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('operating_expenses')}
                    </Dialog.Title>
                    <div className="relative mt-4">                        
                        <div className="relative mb-3">
                          <Select 
                            label={t('type_of_expense')}
                            labelClasses={'!text-xs'}
                            xPlacement={'bottomLeft'} 
                            selectedValue={type} 
                            dropdownClass={'!w-full'} 
                            dropdownData={
                              [
                                { _id: "", label: t('select_type_of_expense') ,value:''} ,
                                ...expenseTypes.map((item) => (
                                    { _id: item?._id, label: item?.name, value: item?._id }
                                ))
                              ]
                            }
                            getSelectedValue={(e) => setType(e._id)}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                          <div className="relative mb-3">
                            <div className={"font-medium text-slate-500 mb-1 !text-xs"}>{t('date_paid')}</div>
                            <Flatpickr
                              ref={flatPicker}
                              className='w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200'
                              placeholder={t('enter_date')}
                              value={date}
                              options={{ 
                                dateFormat: "Y-m-d",
                                //minDate:new Date()
                              }}
                              onChange={(selectedDates, dateStr, instance) => {
                                setDate(dateStr)
                              }}
                            />
                          </div>
                          <div className="relative mb-3">
                            <Input 
                            label={t('amount')} 
                            labelClasses={'!text-xs'} 
                            inputType={'number'}                     
                            inputPlaceholder={t('enter_amount')}
                            inputValue={amount} 
                            setInput={setAmount}
                            />
                          </div>
                        </div>
                        <div className="relative mb-3">
                          <Textarea 
                            label={t('comments')} 
                            labelClasses={'!text-xs'} 
                            inputValue={comments} 
                            inputPlaceholder={t('enter_comments')}
                            setTextarea={setComments}
                          />
                        </div>    
                        <div className="relative mb-3">
                          <Select
                            xPlacement={"bottomLeft"}
                            selectedValue={monthOfExpense}
                            dropdownClass={"!w-full"}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownData={[
                              { _id: "", label: t('select_month_of_expense'), value: "" },
                              ...monthYear().map((item) => (
                                { _id: item, label: item, value: item }
                              ))
                            ]}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) => setMonthOfExpense(e._id)}
                          />
                        </div>           
                    </div>
                    <div className="relative flex justify-center mb-3 mt-6 gap-4">
                        <Button
                        buttonClasses='!bg-teal-500'
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('confirm')}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={loading}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('cancel')} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default ExpensesPopup;
