import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import ImagePopup from '../popup/ImagePopup';

const Image = ({src, width, height, alt, effect, classes, popupSize,  id=''}) => {
  const [image, setImage] = useState('');
  // For Image Popup
  const [enabledImagePopup,setEnabledImagePopup] = useState(false)
  const [currentImage,setCurrentImage] = useState('');

  useEffect(() => {
    setImage(src)
  },[src])
  
  return (
    <>
      <LazyLoadImage
        src={!image ? '/no-data-found.jpg' : image}
        width={width} 
        height={height}
        alt={alt}
        effect={effect}
        className={'w-full h-full ' + (classes)}
        id={id}
        onError={() => setImage('/no-data-found.jpg')}
        onClick={() => {
          setEnabledImagePopup(true)
          setCurrentImage(!image ? '/no-data-found.jpg' : image)
        }}
      />
      {
        (id === 'header-logo')?null:
        <ImagePopup
          isOpen={enabledImagePopup}
          size={popupSize}
          setIsOpen={(val) => setEnabledImagePopup(val)}
          image={currentImage}
          getActionValue={(obj) => {
            setEnabledImagePopup(false);
            setCurrentImage("");
          }}
        />
      }
    </>
  );
};

export default Image;