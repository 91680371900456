import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Table from "../../components/elements/table/Table";
import moment from "moment";
import { DragDropContext } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import toastr from "toastr";
import CancelPopup from "../../components/popup/CancelPopup";
import ManageRenewalDatePopup from "../../components/popup/ManageRenewalDatePopup";
import ManageCompanyInfoPopup from "../../components/popup/ManageCompanyInfoPopup";
import ManageRolePopup from "../../components/popup/ManageRolePopup";
import ManagePasswordPopup from "../../components/popup/ManagePasswordPopup";
import ViewComapnyDetailsPopup from "../../components/popup/ViewComapnyDetailsPopup";
import SubscriptionReport from "../pdf/SubscriptionReport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ManageRenewalPaymentPopup from "../../components/popup/ManageRenewalPaymentPopup";
import ManageNoOfSuccessionPlanPopup from "../../components/popup/ManageNoOfSuccessionPlanPopup";

import { postData } from "../../services/api";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";

const Subscriptions = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Subscriptions");
  const { t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [subscriptionFor, setSubscriptionFor] = useState("");
  const [companies, setCompanies] = useState([]);
  const [plans, setPlans] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [planId, setPlanId] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [rawSubscriptions, setRawSubscriptions] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [accountType, setAccountType] = useState("");
  const othersAction = {
    type: "button",
    link: "/",
    label: t("cancel"),
    icon: "fa-times",
    isVisabled: 2,
  };
  const [actionValue, setActionValue] = useState({});
  const [enabledCancelPopup, setEnabledCancelPopup] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [enabledRenewalDatePopup, setEnabledRenewalDatePopup] = useState(false);
  const [renewalData, setRenewalData] = useState({});
  const [enabledCompanyInfoPopup, setEnabledCompanyInfoPopup] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [enabledViewCompanyPopup, setEnabledViewCompanyPopup] = useState(false);
  const [viewCompanyInfo, setViewCompanyinfo] = useState({});

  const [enabledRoleManagePopup, setEnabledRoleManagePopup] = useState(false);
  const [roleInfo, setRoleInfo] = useState({});
  const [passwordInfo, setPasswordInfo] = useState({});
  const [enabledPasswordManagePopup, setEnabledPasswordManagePopup] = useState(false);
  const [renewalInfo, setRenewalInfo] = useState({});
  const [enabledRenewalInfoPopup, setEnabledRenewalInfoPopup] = useState(false);
  const [enabledSuccessionNoOfPlanPopup,setEnabledSuccessionNoOfPlanPopup] = useState(false);
  const [number,setNumber] = useState('');

  const extraData = [
    role &&
      (role === "master-admin" || (user.permission && user.permission.filter((item) => item === "subscription_resend_invitation_email").length > 0)) && {
        _id: 1,
        label: t("resend_invitation_email"),
        icon: "fa-inbox",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "resend-invitation",
        isVisabled: [1, 3],
        ordering: 2,
      },
    role &&
      role === "master-admin" && {
        _id: 6,
        label: t("role_manage"),
        icon: "fa-users",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "role-manage",
        isVisabled: 1,
        ordering: 2,
      },
    role &&
      (role === "master-admin" || (user.permission && user.permission.filter((item) => item === "subscription_edit_user_info").length > 0)) && {
        _id: 2,
        label: t("edit"),
        icon: "fa-pen-to-square",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "edit-user-info",
        ordering: 1,
      },
    // (role && (role === 'master-admin' || (user.permission && user.permission.filter((item) => item === 'subscription_change_renewal_date').length>0))) &&
    // {
    //   _id: 3,
    //   label: t('renewal_date'),
    //   icon: 'fa-calendar',
    //   link: '/',
    //   type: 'button',
    //   standout: false,
    //   buttonType: 'change-renewal-date',
    //   isVisabled:1,
    //   ordering : 3,
    // },
    role &&
      role === "master-admin" && {
        _id: 3,
        label: t("manage_renewal_data"),
        icon: "fa-calendar",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "manage-renewal-data",
        isVisabled: [1, 3],
        ordering: 2,
      },
    role &&
      role === "master-admin" && {
        _id: 4,
        label: t("paid"),
        icon: "fa-dollar",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "manually-paid",
        isVisabled: 3,
        ordering: 4,
      },
    role &&
      role === "master-admin" && {
        _id: 6,
        label: t("company_information"),
        icon: "fa-circle-info",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "company-information",
        isVisabled: 1,
        ordering: 5,
      },
    role &&
      role === "master-admin" && {
        _id: 5,
        label: t("canceled"),
        icon: "fa-ban",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "manually-canceled",
        isVisabled: 1,
        ordering: 5,
      },
    role &&
      role === "master-admin" && {
        _id: 5,
        label: t("update_password"),
        icon: "fa-key",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "update-password",
        isVisabled: 1,
        ordering: 3,
      },
    role &&
      role === "master-admin" && {
        _id: 6,
        label: t("manage_succession_plan"),
        icon: "fa-users",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "manage-succession-plan",
        isVisabled: 1,
        ordering: 4,
      },
  ];

  const capitalizeString = (str) => {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "others") {
      setEnabledCancelPopup(true);
      setCurrentId(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "resend-invitation") {
      const loadResendInvitation = async () => {
        try {
          const othersData = await postData("subscription/resend-invitation", {
            id: actionValue?.row_id,
          });
          if (othersData.data) {
            setActionValue({});
            toastr.success("Email resent.Please make sure to check your spam email.");
          } else {
            toastr.error(othersData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadResendInvitation();
    } else if (actionValue && actionValue.button_type === "change-renewal-date") {
      setEnabledRenewalDatePopup(true);
      const index = rawSubscriptions.findIndex((item) => item._id === actionValue?.row_id);
      if (index !== -1) {
        setRenewalData({
          _id: actionValue.row_id,
          date: rawSubscriptions[index]?.end_date,
        });
      } else {
        setRenewalData({
          _id: actionValue.row_id,
          date: new Date(),
        });
      }
    } else if (actionValue && actionValue.button_type === "edit-user-info") {
      setEnabledCompanyInfoPopup(true);
      const index = rawSubscriptions.findIndex((item) => item._id === actionValue?.row_id);
      setCompanyInfo({
        _id: actionValue.row_id,
        company_name: rawSubscriptions[index]?.company_name,
        number_of_employees: rawSubscriptions[index]?.number_of_employees,
        designation: rawSubscriptions[index]?.designation,
        name: rawSubscriptions[index]?.name,
        email: rawSubscriptions[index]?.email,
        phone: rawSubscriptions[index]?.phone,
        price: rawSubscriptions[index]?.price,
        status: rawSubscriptions[index]?.status,
        image: rawSubscriptions[index]?.image,
        staffs: rawSubscriptions[index]?.staffs,
        end_date: rawSubscriptions[index]?.end_date,
        account_type: rawSubscriptions[index]?.account_type,
        renewal_price: rawSubscriptions[index]?.renewal_price,
        salesCommissionPay: rawSubscriptions[index]?.salesCommissionPay,
        consultantCommissionPay: rawSubscriptions[index]?.consultantCommissionPay,
        appointmentSetterCommissionPay: rawSubscriptions[index]?.appointmentSetterCommissionPay,
        salesAdminCommissionPay: rawSubscriptions[index]?.salesAdminCommissionPay,
        subscription_type: rawSubscriptions[index]?.subscription_type,
      });
    } else if (actionValue && actionValue.button_type === "company-information") {
      setEnabledViewCompanyPopup(true);
      const index = rawSubscriptions.findIndex((item) => item._id === actionValue?.row_id);
      let salesPersion = "";
      let consultantPersion = "";
      let appointmentSetterPersion = "";
      let sales = rawSubscriptions[index]?.user_details.length > 0 && rawSubscriptions[index]?.user_details[0].staffs && rawSubscriptions[index]?.user_details[0].staffs.filter((item) => item.code === "sales-staff");
      if (sales && sales.length > 0) {
        let salesId = sales[0].staff_id;
        let salesPersionArray = rawSubscriptions[index]?.staff_details.filter((itm) => itm._id === salesId);
        if (salesPersionArray && salesPersionArray.length > 0) {
          salesPersion = salesPersionArray[0].first_name + " " + salesPersionArray[0].last_name;
        }
      }
      let consultant = rawSubscriptions[index]?.user_details.length > 0 && rawSubscriptions[index]?.user_details[0].staffs && rawSubscriptions[index]?.user_details[0].staffs.filter((item) => item.code === "consultant");
      if (consultant && consultant.length > 0) {
        let consultantId = consultant[0].staff_id;
        let consultantPersionArray = rawSubscriptions[index]?.staff_details.filter((itm) => itm._id === consultantId);
        if (consultantPersionArray && consultantPersionArray.length > 0) {
          consultantPersion = consultantPersionArray[0].first_name + " " + consultantPersionArray[0].last_name;
        }
      }
      let appointmentSetter = rawSubscriptions[index]?.user_details.length > 0 && rawSubscriptions[index]?.user_details[0].staffs && rawSubscriptions[index]?.user_details[0].staffs.filter((item) => item.code === "appointment-setter");
      if (appointmentSetter && appointmentSetter.length > 0) {
        let appointmentSetterId = appointmentSetter[0].staff_id;
        let appointmentSetterPersionArray = rawSubscriptions[index]?.staff_details.filter((itm) => itm._id === appointmentSetterId);
        if (appointmentSetterPersionArray && appointmentSetterPersionArray.length > 0) {
          appointmentSetterPersion = appointmentSetterPersionArray[0].first_name + " " + appointmentSetterPersionArray[0].last_name;
        }
      }
      setViewCompanyinfo({
        _id: actionValue.row_id,
        company_name: rawSubscriptions[index]?.company_name,
        number_of_employees: rawSubscriptions[index]?.number_of_employees,
        designation: rawSubscriptions[index]?.designation,
        name: rawSubscriptions[index]?.name,
        email: rawSubscriptions[index]?.email,
        email_verified: rawSubscriptions[index]?.email_verified,
        phone: rawSubscriptions[index]?.phone,
        salesPersion: salesPersion,
        consultantPersion: consultantPersion,
        appointmentSetterPersion: appointmentSetterPersion,
        activation_code: rawSubscriptions[index]?.activation_code,
        account_type: rawSubscriptions[index]?.account_type,
      });
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("subscription/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = subscriptions.findIndex((item) => item._id === actionValue?.row_id);
            if (index !== -1) {
              subscriptions.splice(index, 1);
              setSubscriptions(
                subscriptions.map((it) => {
                  return it;
                })
              );
            }
            const index1 = rawSubscriptions.findIndex((item) => item._id === actionValue?.row_id);
            if (index1 !== -1) {
              rawSubscriptions.splice(index, 1);
              setRawSubscriptions(
                rawSubscriptions.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (actionValue && (actionValue.button_type === "manually-paid" || actionValue.button_type === "manually-canceled")) {
      const loadStatusChangeRecord = async () => {
        var status = "";
        if (actionValue.button_type === "manually-paid") {
          status = 1;
        }
        if (actionValue.button_type === "manually-canceled") {
          status = 3;
        }
        try {
          const statusData = await postData("subscription/status-change", {
            id: actionValue.row_id,
            date: new Date(),
            status: status,
          });
          if (statusData.data) {
            toastr.success(statusData.message);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChangeRecord();
    } else if (actionValue && actionValue.button_type === "role-manage") {
      setEnabledRoleManagePopup(true);
      const index = rawSubscriptions.findIndex((item) => item._id === actionValue?.row_id);
      setRoleInfo({
        _id: rawSubscriptions[index]?.company_id,
        role: rawSubscriptions[index]?.role,
      });
      setIsUpdate(false);
    } else if (actionValue && actionValue.button_type === "edit") {
      setIsUpdate(true);
    } else if (actionValue && actionValue.button_type === "update-password") {
      setEnabledPasswordManagePopup(true);
      const index = rawSubscriptions.findIndex((item) => item._id === actionValue?.row_id);
      setPasswordInfo({
        _id: rawSubscriptions[index]?.company_id,
      });
      setIsUpdate(false);
    } else if (actionValue && actionValue.button_type === "manage-renewal-data") {
      setEnabledRenewalInfoPopup(true);
      const index = rawSubscriptions.findIndex((item) => item._id === actionValue?.row_id);
      setRenewalInfo({
        _id: actionValue.row_id,
        company_name: rawSubscriptions[index]?.company_name,
        price: rawSubscriptions[index]?.price,
        status: rawSubscriptions[index]?.status,
        end_date: rawSubscriptions[index]?.end_date,
        account_type: rawSubscriptions[index]?.account_type,
        renewal_price: rawSubscriptions[index]?.renewal_price,
        subscription_type: rawSubscriptions[index]?.subscription_type,
      });
    } else if(actionValue && actionValue.button_type === "manage-succession-plan") {
      setEnabledSuccessionNoOfPlanPopup(true);
      setCurrentId(actionValue.row_id);
      const index = rawSubscriptions.findIndex((item) => item._id === actionValue?.row_id);
      let planInfo = rawSubscriptions[index]?.plan_info;
      let successionPlanCount = 0;
      if(planInfo?.features && planInfo?.features?.length>0){
        successionPlanCount = planInfo?.features?.find(it => it?.code === 'succession-plans-per-year')?.inputValue; 
      }
      setNumber(successionPlanCount);
    }
  }, [actionValue,rawSubscriptions]);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    async function planData() {
      try {
        const result = await postData("plans/list", {});
        if (result.data) {
          setPlans(result.data);
        } else {
          console.log("plans list message", result.message);
        }
      } catch (error) {
        console.log("Error plans list catch", error.message);
      }
    }
    planData();

    async function companyData() {
      try {
        const result = await postData("usertype/list", {
          code: "customer-admin",
          status: 1,
        });
        if (result.data) {
          setCompanies(result.data);
        } else {
          console.log("companies list message", result.message);
        }
      } catch (error) {
        console.log("Error companies list catch", error.message);
      }
    }
    companyData();
  }, []);

  useEffect(() => {
    // Email template List
    const loadEmailTemplate = async () => {
      setLoading(true);
      try {
        const result = await postData("subscription/list", {
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: status,
          companyId: companyId,
          planId: planId,
          subscriptionFor: subscriptionFor,
          accountType: accountType,
          paymentStatus: paymentStatus,
        });
        if (result.data) {
          setRawSubscriptions(
            result.data.map((value) => {
              let salesCommissionPay = value?.staff_commission.length > 0 && value?.staff_commission.filter((item) => item.staff_code === "sales-staff");
              let consultantCommissionPay = value?.staff_commission.length > 0 && value?.staff_commission.filter((item) => item.staff_code === "consultant");
              let appointmentSetterCommissionPay = value?.staff_commission.length > 0 && value?.staff_commission.filter((item) => item.staff_code === "appointment-setter");
              let salesAdminCommissionPay = value?.staff_commission.length > 0 && value?.staff_commission.filter((item) => item.staff_code === "sales-admin");
              return {
                ...value,
                salesCommissionPay: salesCommissionPay,
                consultantCommissionPay: consultantCommissionPay,
                appointmentSetterCommissionPay: appointmentSetterCommissionPay,
                salesAdminCommissionPay: salesAdminCommissionPay,
              };
            })
          );
          setSubscriptions(
            result.data.map((value, key) => {
              //let renewalType  = (role && role === 'master-admin')?'date':'text';
              // file?.path
              let salesPersion = "";
              let consultantPersion = "";
              let appointmentSetterPersion = "";
              let sales = value?.user_details.length > 0 && value?.user_details[0].staffs && value?.user_details[0].staffs.filter((item) => item.code === "sales-staff");
              if (sales && sales.length > 0) {
                let salesId = sales[0].staff_id;
                let salesPersionArray = value?.staff_details.filter((itm) => itm._id === salesId);
                if (salesPersionArray && salesPersionArray.length > 0) {
                  salesPersion = salesPersionArray[0].first_name + " " + salesPersionArray[0].last_name;
                }
              }
              let consultant = value?.user_details.length > 0 && value?.user_details[0].staffs && value?.user_details[0].staffs.filter((item) => item.code === "consultant");
              if (consultant && consultant.length > 0) {
                let consultantId = consultant[0].staff_id;
                let consultantPersionArray = value?.staff_details.filter((itm) => itm._id === consultantId);
                if (consultantPersionArray && consultantPersionArray.length > 0) {
                  consultantPersion = consultantPersionArray[0].first_name + " " + consultantPersionArray[0].last_name;
                }
              }
              let appointmentSetter = value?.user_details.length > 0 && value?.user_details[0].staffs && value?.user_details[0].staffs.filter((item) => item.code === "appointment-setter");
              if (appointmentSetter && appointmentSetter.length > 0) {
                let appointmentSetterId = appointmentSetter[0].staff_id;
                let appointmentSetterPersionArray = value?.staff_details.filter((itm) => itm._id === appointmentSetterId);
                if (appointmentSetterPersionArray && appointmentSetterPersionArray.length > 0) {
                  appointmentSetterPersion = appointmentSetterPersionArray[0].first_name + " " + appointmentSetterPersionArray[0].last_name;
                }
              }
              let noOfSuccessionPlan = value?.individual_details.filter((it) => it.conclusion_details.length > 0);
              let _statusLabel = "";
              let _statusType = "";
              if (value?.payment_status && value?.payment_status === 1) {
                _statusLabel = t("paid");
                _statusType = "success";
              } else if (value?.payment_status && value?.payment_status === 2) {
                _statusLabel = t("unpaid");
                _statusType = "danger";
              } else if (value?.payment_status && value?.payment_status === 3) {
                _statusLabel = t("unpaid") + " (" + t("trial") + ")";
                _statusType = "danger";
              }
              return {
                _id: value?._id,
                email: value?.email,
                userIds: value?.user_details[0]?._id,
                rowData: [
                  { _id: 1, width: 15, type: "user", data: value?.company_name, image: value?.image, isColor: "" },
                  { _id: 3, width: 40, type: "text", data: value?.name, isColor: "" },
                  //{ _id:4, width:40, type:'text', data:value?.email,isColor:'' },
                  //{ _id:5, width:15, type:'status', statusLabel:(value?.email_verified && value?.email_verified === 1)?t('verified'):t('not_verified'), statusType:(value?.email_verified && value?.email_verified === 1)?'success':'warning'},
                  //{ _id:6, width:40, type:'text', data:value?.phone,isColor:'' },
                  { _id: 2, width: 40, type: "text", data: value?.number_of_employees, isColor: "" },
                  { _id: 7, width: 40, type: "text", data: noOfSuccessionPlan.length, isColor: "" },
                  { _id: 9, width: 20, type: "text", data: value && value?.created_at ? moment(new Date(value?.created_at)).tz("America/Denver").format("MMM Do YYYY") : "N/A", isColor: "" },
                  { _id: 8, width: 15, type: "text", data: "$" + value?.original_price.toFixed(2), isColor: "" },
                  { _id: 10, width: 20, type: "text", data: value && value?.end_date ? moment(new Date(value?.end_date)).tz("America/Denver").format("MMM Do YYYY") : "N/A", isColor: value?.is_cancel && value?.is_cancel === 1 ? "text-red-600" : "" },
                  { _id: 21, width: 15, type: "text", data: value && value.renewal_price !== undefined ? "$" + Number(value.renewal_price).toFixed(2) : "N/A", isColor: "" },
                  { _id: 11, width: 20, type: "text", data: value && value?.cancel_date ? moment(new Date(value?.cancel_date)).tz("America/Denver").format("MMM Do YYYY") : "N/A", isColor: "" },
                  { _id: 17, width: 15, type: "text", data: value?.account_type ? capitalizeString(value?.account_type) : "", isColor: "" },
                  {
                    _id: 15,
                    width: 15,
                    type: "status",
                    statusLabel: value?.status && value?.status === 1 ? "Active" : value?.status && value?.status === 2 ? t("canceled") : t("unpaid"),
                    statusType: value?.status && value?.status === 1 ? "success" : value?.status && value?.status === 2 ? "danger" : "danger",
                    isColor: "",
                  },
                  { _id: 12, width: 20, type: "text", data: salesPersion ? salesPersion : t("none"), isColor: "" },
                  //{ _id:13, width:20, type:'text', data:(consultantPersion)?consultantPersion:t('none'),isColor:''},
                  //{ _id:14, width:20, type:'text', data:(appointmentSetterPersion)?appointmentSetterPersion:t('none'),isColor:''},
                  //{ _id:18, width:40, type:'text', data: value?.activation_code,isColor:'' },
                  {
                    _id: 19,
                    width: 15,
                    type: "status",
                    statusLabel: _statusLabel,
                    // (value?.payment_status && value?.payment_status === 1)?t('paid'):
                    // (value?.payment_status && value?.payment_status === 2)?t('unpaid'):
                    // (value?.payment_status && value?.payment_status === 3)?t('trial'):
                    // (value?.payment_status && value?.payment_status === 4)?t('inactive'):
                    // (value?.payment_status && value?.payment_status === 5)?t('sales_demo'):'',
                    statusType: _statusType,
                    // (value?.payment_status && value?.payment_status === 1)?'success':
                    // (value?.payment_status && value?.payment_status === 2)?'danger':
                    // (value?.payment_status && value?.payment_status === 3)?'info':
                    // (value?.payment_status && value?.payment_status === 4)?'same-success':
                    // (value?.payment_status && value?.payment_status === 5)?'warning':'',
                    isColor: "",
                  },
                  //{ _id:20, width:15, type:'status', statusLabel:(value?.subscription_for && value?.subscription_for === 'purchase_plan')?t('plan_purchase'):(value?.subscription_for && value?.subscription_for === 'additional_purchase')?t('additional_purchase'):'', statusType:(value?.subscription_for && value?.subscription_for === 'purchase_plan')?'info':(value?.subscription_for && value?.subscription_for === 'additional_purchase')?'':'warning',isColor:'' },
                  { _id: 16, width: 10, type: "action", data: [{ others: value?.is_cancel === 2 && value?.subscription_type === "stripe" ? 2 : 1 }, { "resend-invitation": value?.status }, { "change-renewal-date": value?.status }, { "manually-paid": value?.status }, { "company-information": 1 }, { "manually-canceled": value?.status }, { "role-manage": 1 }, { "update-password": 1 }, { "manage-renewal-data": value?.status },{"manage-succession-plan": value?.payment_status}], isColor: "" },
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadEmailTemplate();
  }, [limit, offset, sortQuery, status, companyId, planId, actionValue, isUpdate, subscriptionFor, accountType, paymentStatus]);

  useEffect(() => {
    // Report Data
    const loadReportData = async () => {
      setLoading(true);
      try {
        const result = await postData("subscription/list", {
          sortQuery: sortQuery,
          status: status,
          companyId: companyId,
          planId: planId,
          subscriptionFor: subscriptionFor,
          accountType: accountType,
          paymentStatus: paymentStatus,
        });
        if (result.data) {
          setReportData(
            result.data.map((value) => {
              let salesCommissionPay = value?.staff_commission.length > 0 && value?.staff_commission.filter((item) => item.staff_code === "sales-staff");
              let consultantCommissionPay = value?.staff_commission.length > 0 && value?.staff_commission.filter((item) => item.staff_code === "consultant");
              let appointmentSetterCommissionPay = value?.staff_commission.length > 0 && value?.staff_commission.filter((item) => item.staff_code === "appointment-setter");
              let salesAdminCommissionPay = value?.staff_commission.length > 0 && value?.staff_commission.filter((item) => item.staff_code === "sales-admin");
              return {
                ...value,
                salesCommissionPay: salesCommissionPay,
                consultantCommissionPay: consultantCommissionPay,
                appointmentSetterCommissionPay: appointmentSetterCommissionPay,
                salesAdminCommissionPay: salesAdminCommissionPay,
              };
            })
          );
          
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadReportData();
  }, [ sortQuery, status, companyId, planId, actionValue, isUpdate, subscriptionFor, accountType, paymentStatus]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number((totalDataCount>0)?1:0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };

  const header = [
    {
      name: t("company_name"),
    },
    {
      name: t("company_administrator"),
    },
    {
      name: t("#_Of_employees"),
    },
    {
      name: t("of_completed_plans"),
    },
    // {
    //   name : t('start_date')
    // },
    // {
    //   name :t('starting_price')
    // },
    // {
    //   name :t('renewal_date')
    // },
    // {
    //   name :t('renewal_price')
    // },
    // {
    //   name :t('cancellation_date')
    // },
    // {
    //   name :t('current_status')
    // },
    // {
    //   name :t('paid')+'/'+t('unpaid')
    // }
  ];

  const tableHeadData = [
    { _id: 1, width: 15, name: t("company_name"), value: "lower_company_name", align: "left", isSort: true, isFilter: false },
    { _id: 3, width: 15, name: t("company_administrator"), value: "lower_name", align: "left", isSort: true, isFilter: false },
    //{_id:4, width:15, name:t('administrator_email'), value:'email', align:'left', isSort:false, isFilter:false},
    //{_id:5, width:15, name:t('email_verified'), value:'email_verified', align:'left', isSort:false, isFilter:false},
    //{_id:6, width:15, name:t('administrator_phone'), value:'phone', align:'left', isSort:false, isFilter:false},
    { _id: 2, width: 40, name: t("#_Of_employees"), value: "sorting_number_of_employees", align: "left", isSort: true, isFilter: false },
    { _id: 7, width: 40, name: t("of_completed_plans"), value: "number_of_succession_plan", align: "left", isSort: true, isFilter: false },
    { _id: 9, width: 20, name: t("start_date"), value: "created_at", align: "left", isSort: true, isFilter: false },
    { _id: 8, width: 15, name: t("starting_price"), value: "original_price", align: "left", isSort: true, isFilter: false },
    { _id: 10, width: 20, name: t("renewal_date"), value: "end_date", align: "left", isSort: true, isFilter: false },
    { _id: 21, width: 15, name: t("renewal_price"), value: "renewal_price", align: "left", isSort: true, isFilter: false },
    { _id: 11, width: 20, name: t("cancellation_date"), value: "cancel_date", align: "left", isSort: true, isFilter: false },
    { _id: 17, width: 15, name: t("account_type"), value: "status", align: "left", isSort: true, isFilter: false },
    { _id: 15, width: 15, name: t("current_status"), value: "status", align: "left", isSort: true, isFilter: false },
    { _id: 12, width: 20, name: t("sales_person"), value: "lower_staff_details", align: "left", isSort: true, isFilter: false },
    //{_id:13, width:20, name:t('appointment'),value:'cancel_date', align:'left', isSort:false, isFilter:false},
    //{_id:14, width:20, name:t('consultant'),value:'cancel_date', align:'left', isSort:false, isFilter:false},
    //{_id:18, width:20, name:t('activation_code'),value:'activation_code', align:'left', isSort:false, isFilter:false},
    { _id: 19, width: 20, name: t("paid") + "/" + t("unpaid"), value: "status", align: "left", isSort: false, isFilter: false },
    //{_id:20, width:20, name:t('subscription_for'),value:'subscription_for', align:'left', isSort:false, isFilter:false},
    { _id: 16, width: 10, name: t("action"), value: "", align: "right", isSort: false, isFilter: false },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(subscriptions, result.source.index, result.destination.index);
    setSubscriptions(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">{t("subscriptions")}</div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          <button type={"button"} className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"} onClick={(e) => getDownload(e)}>
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink document={<SubscriptionReport records={reportData} header={header} user={user} />} fileName="subscription.pdf">
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("export_data"))}
              </PDFDownloadLink>
            </div>
          </button>
          <Button
            //buttonClasses={'!bg-fuchsia-500'}
            buttonType={"button"}
            buttonIcon={"fa-light fa-list"}
            buttonIconPosition={"left"}
            buttonLabel={t("transactions")}
            buttonHasLink={true}
            buttonLink={"/transactions"}
          />
        </div>
      </div>
      <div className="relative flex flex-wrap 2xl:flex-nowrap  items-center mb-4 gap-3">
        <div className="relative">
          <Select
            xPlacement={"bottomLeft"}
            dropdownButtonClass={"!bg-white"}
            dropdownClass="w-32"
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        {role && role === "master-admin" && (
            <div className="relative w-48">
              <Select xPlacement={"right"} dropdownButtonClass={"!bg-white"} dropdownClass="w-48" transitionClass={"!w-auto"} selectedValue={companyId} search={true} dropdownData={[{ _id: "", label: t("select_company") }, ...companies.map((company) => ({ _id: company?._id, label: company?.company_name, value: company?._id }))]} getSelectedValue={(e) => setCompanyId(e.value)} />
            </div>
          )}
          <div className="relative w-48">
            <Select xPlacement={"right"} dropdownButtonClass={"!bg-white"}  dropdownClass="w-48" transitionClass={"!w-auto"} selectedValue={planId} dropdownData={[{ _id: "", label: t("select_plan") }, ...plans.map((plan) => ({ _id: plan?._id, label: plan?.name, value: plan?._id }))]} getSelectedValue={(e) => setPlanId(e.value)} />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"bottomRight"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass="w-48"
              transitionClass={"!w-auto"}
              selectedValue={accountType}
              dropdownData={[
                { _id: "", label: t("select_account_type"), value: "" },
                { _id: "business", label: capitalizeString("business"), value: "business" },
                { _id: "demonstration", label: capitalizeString("demonstration"), value: "demonstration" },
                { _id: "consultant", label: capitalizeString("consultant"), value: "consultant" },
                { _id: "developer", label: capitalizeString("developer"), value: "developer" },
                { _id: "training", label: capitalizeString("training"), value: "training" },
              ]}
              getSelectedValue={(e) => setAccountType(e.value)}
            />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"bottomRight"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass="w-48"
              transitionClass={"!w-auto"}
              selectedValue={paymentStatus}
              dropdownData={[
                { _id: "", label: t("select_account_status"), value: "" },
                { _id: 1, label: t("paid"), value: 1 },
                { _id: 2, label: t("unpaid"), value: 2 },
                { _id: 3, label: t("trial"), value: 3 },
                // { _id: 4, label: t('inactive'),value:4},
                // { _id: 5, label: t('sales_demo'),value:5}
              ]}
              getSelectedValue={(e) => setPaymentStatus(e.value)}
            />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"bottomRight"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass="w-48"
              transitionClass={"!w-auto"}
              selectedValue={status}
              dropdownData={[
                { _id: "", label: t("all_statuses") },
                { _id: 1, label: t("active"), value: 1 },
                { _id: 2, label: t("canceled"), value: 2 },
                { _id: 3, label: t("unpaid"), value: 3 },
              ]}
              getSelectedValue={(e) => setStatus(e.value)}
            />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"bottomRight"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass="w-48"
              transitionClass={"!w-auto"}
              selectedValue={subscriptionFor}
              dropdownData={[
                { _id: "", label: t("all_subscription_for") },
                { _id: 1, label: t("plan_purchase"), value: "purchase_plan" },
                { _id: 2, label: t("additional_purchase"), value: "additional_purchase" },
              ]}
              getSelectedValue={(e) => setSubscriptionFor(e.value)}
            />
          </div>
          <div className="relative">
            <Button
              buttonClasses={"!bg-teal-400 !text-slate-600 border border-teal-400 !h-10 !px-3 !text-white"}
              buttonType={"button"}
              buttonIcon={"fa-light fa-sync"}
              buttonIconPosition={"left"}
              // buttonLabel={t("reset")}
              buttonLabelClasses={"!text-sm"}
              buttonHasLink={false}
              functions={() => {
                setCompanyId("");
                setPlanId("");
                setAccountType("");
                setPaymentStatus("");
                setStatus("");
                setSubscriptionFor("");
              }}
            />
          </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow ">
          <Table tableData={subscriptions} tableHeadData={tableHeadData} totalRecord={totalDataCount} loading={loading} getSortValue={setSortValue} getSortKey={setSortKey} detailsUrl={"/transactions"} {...(role && (role === "master-admin" || (user.permission && user.permission.filter((item) => item === "subscription_delete").length > 0)) ? { isDeleteEnabled: true } : "")} getActionValue={(obj) => setActionValue(obj)} othersAction={othersAction} isDraggable={false} extraData={extraData} loginAs={"/login-as"} />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount}/>
      ):(
        <div className="flex justify-center sm:justify-between my-4">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}  
        </div>
      )}
      {enabledCancelPopup && (
        <CancelPopup
          isOpen={enabledCancelPopup}
          setIsOpen={(val) => setEnabledCancelPopup(val)}
          currentId={currentId}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledCancelPopup(false);
            setCurrentId("");
          }}
        />
      )}
      {enabledRenewalDatePopup && (
        <ManageRenewalDatePopup
          isOpen={enabledRenewalDatePopup}
          setIsOpen={(val) => setEnabledRenewalDatePopup(val)}
          data={renewalData}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledRenewalDatePopup(false);
            setRenewalData({});
          }}
        />
      )}
      {enabledCompanyInfoPopup && (
        <ManageCompanyInfoPopup
          isOpen={enabledCompanyInfoPopup}
          setIsOpen={(val) => setEnabledCompanyInfoPopup(val)}
          data={companyInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledCompanyInfoPopup(false);
            setCompanyInfo({});
          }}
        />
      )}
      {enabledRoleManagePopup && (
        <ManageRolePopup
          isOpen={enabledRoleManagePopup}
          setIsOpen={(val) => setEnabledRoleManagePopup(val)}
          data={roleInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledRoleManagePopup(false);
            //setCompanyInfo({})
          }}
        />
      )}
      {enabledPasswordManagePopup && (
        <ManagePasswordPopup
          isOpen={enabledPasswordManagePopup}
          setIsOpen={(val) => setEnabledPasswordManagePopup(val)}
          data={passwordInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledPasswordManagePopup(false);
            //setCompanyInfo({})
          }}
        />
      )}
      {enabledViewCompanyPopup && (
        <ViewComapnyDetailsPopup
          isOpen={enabledViewCompanyPopup}
          setIsOpen={(val) => setEnabledViewCompanyPopup(val)}
          data={viewCompanyInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledViewCompanyPopup(false);
          }}
        />
      )}
      {enabledRenewalInfoPopup && (
        <ManageRenewalPaymentPopup
          isOpen={enabledRenewalInfoPopup}
          setIsOpen={(val) => setEnabledRenewalInfoPopup(val)}
          data={renewalInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledRenewalInfoPopup(false);
            setRenewalInfo({});
          }}
        />
      )}
      {enabledSuccessionNoOfPlanPopup && (
        <ManageNoOfSuccessionPlanPopup
          isOpen={enabledSuccessionNoOfPlanPopup}
          setIsOpen={(val) => setEnabledSuccessionNoOfPlanPopup(val)}
          data={{
            id:currentId,
            number:number
          }}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledSuccessionNoOfPlanPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default Subscriptions;
