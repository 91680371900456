import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import Searching from "../Searching";
import NoDataFound from "../NoDataFound";

import { postData } from "../../services/api";
import moment from "moment";
import toastr from "toastr";

import { useTranslation } from "react-i18next";

const ManageRenewalPaymentPopup = ({ isOpen, setIsOpen = () => {}, data, getActionValue = () => {} }) => {
  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  //const timezone =  Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Denver' });
  const role = localStorage.getItem("role");
  const [companyName, setCompanyName] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [date, setDate] = useState("");
  const [renewalPrice, setRenewalPrice] = useState("");
  const [accountType, setAccountType] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [shoeRenewalPrice, setShowRenewalPrice] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isConfirm, setIsConfirm] = useState(2);

  const [renewalInfo,setRenewalInfo] = useState({});
  const [isShow,setIsShow] = useState(false);
  const [showEditRenewalPrice,setShowEditRenewalPrice] = useState("");
  const [editPrice,setEditPrice] = useState('');
  const [startDate,setStartDate] = useState('');
  const [endDate,setEndDate] = useState('');
  const [editId,setEditId] = useState('');
  const [isEdit,setIsEdit] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const currentTime = moment(new Date(date + " 23:59:00")).format("YYYY-MM-DD HH:mm:ss");
    // const formData = new FormData();
    // formData.append("id", data?._id);
    // formData.append("start_date", (date)?new Date(currentTime).toUTCString():"");
    // formData.append("renewal_price", renewalPrice);
    // formData.append("subscription_type",subscriptionType);
    try {
      setLoading(true);
      const result = await postData("subscription/renewal-payment", {
        id: data?._id,
        start_date: date ? new Date(currentTime).toUTCString() : "",
        renewal_price: renewalPrice,
        subscription_type: subscriptionType,
        status : status
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "manage",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadTransactions = async () => {
      setProcessing(true);
      try {
        const result = await postData("transaction/list", {
          subscription_id: data?._id,
        });
        if (result.data) {
          setTransactions(result.data);
        }
        setProcessing(false);
      } catch (err) {
        setProcessing(false);
        console.log(err.message);
      }
    };
    loadTransactions();
  }, []);

  useEffect(() => {    
    setStatus(data?.status);
    setSubscriptionType(data?.subscription_type);
    setAccountType(data?.account_type ? data?.account_type : "");
    if (data?.end_date) {
      setDate(moment(new Date(data?.end_date)).tz("America/Denver").format("YYYY-MM-DD"));
    }
    if(data?.status === 3){
      let startDate = new Date();
      startDate.setDate(startDate.getDate() + 1);
      setDate(moment(startDate).tz("America/Denver").format("YYYY-MM-DD"));
    }
    setShowRenewalPrice(data?.renewal_price);
  }, [data]);

  useEffect(() => {
    setEditId(renewalInfo?._id);
    setEditPrice(renewalInfo?.price);
    setStartDate((renewalInfo?.start_date)?moment(new Date(renewalInfo?.start_date)).tz("America/Denver").format("YYYY-MM-DD"):null);
    setEndDate((renewalInfo?.end_date)?moment(new Date(renewalInfo?.end_date)).tz("America/Denver").format("YYYY-MM-DD"): null);
    setShowEditRenewalPrice(renewalInfo?.price);
  },[renewalInfo])  

  const onEditRenewal = async (e) => {
    e.preventDefault();
    setIsEdit(true);
    const currentStartTime = moment(new Date(startDate + " 23:59:00")).format("YYYY-MM-DD HH:mm:ss");
    const currentEndTime = moment(new Date(endDate + " 23:59:00")).format("YYYY-MM-DD HH:mm:ss");
    try {
      setLoading(true);
      const result = await postData("subscription/edit-renewal-payment", {
        id: editId,
        start_date: startDate ? new Date(currentStartTime).toUTCString() : "",
        end_date : endDate ? new Date(currentEndTime).toUTCString() : "",
        price: editPrice,
        subscription_type: subscriptionType,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "manage",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setIsEdit(false);
      } else {
        toastr.error(result.message);
        setIsEdit(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsEdit(false);
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-3xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {t("manage_renewal_data")}
                  </Dialog.Title>
                  <div className="relative overflow-x-auto mt-4">
                    <table className="w-full text-sm text-left">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-900">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-slate-400 font-normal text-xs whitespace-nowrap rounded-tl-md">
                            {t("price")}
                          </th>
                          <th scope="col" className="px-6 py-3 text-slate-400 font-normal text-xs whitespace-nowrap">
                            {t("start_date")}
                          </th>
                          <th scope="col" className="px-6 py-3 text-slate-400 font-normal text-xs whitespace-nowrap">
                            {t("end_date")}
                          </th>
                          <th scope="col" className="px-6 py-3 text-slate-400 font-normal text-xs whitespace-nowrap">
                            {t("status")}
                          </th>
                          <th scope="col" className="px-6 py-3 text-slate-400 font-normal text-xs whitespace-nowrap">
                            {t("payment_via")}
                          </th>
                          <th scope="col" className="px-6 py-3 text-slate-400 font-normal text-xs whitespace-nowrap rounded-tr-md">
                            {t("action")}
                          </th>
                        </tr>
                      </thead>
                      {
                        transactions && transactions.length > 0 ? (
                          <tbody>
                            { transactions.map((item, index) => {
                                return (
                                  <tr className="bg-white border-b" key={index}>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                      {"$" + item?.price.toFixed(2)}
                                    </th>
                                    <td className="px-6 py-4">{item && item?.start_date ? moment(new Date(item?.start_date)).format("MMM Do YYYY") : "N/A"}</td>
                                    <td className="px-6 py-4">{item && item?.end_date ? moment(new Date(item?.end_date)).format("MMM Do YYYY") : "N/A"}</td>
                                    <td className="px-6 py-4">
                                      <div className="flex items-center">{item?.status && item?.status === 1 ? <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 bg-green-100 text-green-600"}>{t("active")}</div> : <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 bg-amber-100 text-amber-600"}>{t("expire")}</div>}</div>
                                    </td>
                                    <td className="px-6 py-4">
                                      <div className="flex items-center">{(item?.subscription_details?.subscription_type && item?.subscription_details?.subscription_type === 'manual') ? <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 bg-blue-100 text-blue-600"}>{t("manual")}</div> : <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 bg-green-100 text-green-600"}>{t("stripe")}</div>}</div>
                                    </td>
                                    <td className="px-6 py-4 flex justify-end">
                                      {
                                        (item?.subscription_details?.subscription_type && item?.subscription_details?.subscription_type === 'manual') && 
                                        <Button
                                          buttonClasses={"!p-0 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400 !w-4 !h-4"}
                                          buttonType={"button"}
                                          buttonIcon={"fa-regular fa-pencil"}
                                          buttonIconPosition={"left"}
                                          buttonHasLink={false}
                                          functions={() => {
                                            setRenewalInfo({
                                              _id:item?._id,
                                              price : item?.price,
                                              start_date : item?.start_date,
                                              end_date : item?.end_date,
                                              status: item?.status,
                                            })
                                            setIsShow(true)
                                          }}
                                        />
                                      }
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        ) :(
                          <tbody>
                            <tr className="bg-white border-b">
                              <td className="px-6 py-4" colspan={6}>
                                {
                                  processing?<Searching label={t('searching')}/>:<NoDataFound label={t('no_data_found')}/>
                                }
                              </td>
                            </tr>
                          </tbody>
                        )
                      }
                    </table>
                  </div>
                  {
                    isShow && 
                    <div className="py-3 px-6 border border-slate-200 rounded relative z-[1] mt-2">
                      <Button
                        buttonClasses={
                          "!p-0 !flex !items-center !justify-center !text-rose-500 border border-slate-200 shadow rounded-full !w-8 !h-8 !text-lg absolute right-0 -top-3 -right-3 !bg-white z-[2]"
                        }
                        buttonType={"button"}
                        buttonIcon={"fa-regular fa-xmark"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => {
                          setStartDate(null);
                          setEndDate(null);
                          setEditPrice(null);
                          setIsShow(false);
                          setRenewalInfo({})
                        }}
                      />
                      <div className="items-end gap-4 mb-4">
                        <div className="grid sm:grid-cols-2 gap-4 relative">
                          <div className="relative w-full">
                            <Flatpickr
                              ref={flatPicker}
                              className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                              placeholder={t("enter_renewal_date")}
                              value={startDate}
                              options={{
                                dateFormat: "Y-m-d",
                                minDate: new Date(),
                                timezone: "America/Denver",
                              }}
                              onChange={(selectedDates, dateStr, instance) => {
                                setStartDate(dateStr);
                              }}
                            />
                          </div>
                          <div className="relative w-full">
                            <Flatpickr
                              ref={flatPicker}
                              className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                              placeholder={t("enter_renewal_date")}
                              value={endDate}
                              options={{
                                dateFormat: "Y-m-d",
                                minDate: new Date(),
                                timezone: "America/Denver",
                              }}
                              onChange={(selectedDates, dateStr, instance) => {
                                setEndDate(dateStr);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-start gap-4 relative">
                        <div className="relative w-full">
                          <Input 
                            label={t("renewal_price")} 
                            labelClasses={"!text-xs"} 
                            inputType={"number"} 
                            inputPlaceholder={t("enter_renewal_price")} 
                            inputValue={editPrice} 
                            setInput={setEditPrice}
                            errorType={showEditRenewalPrice ? "info" : ""} 
                            errorText={showEditRenewalPrice ? "Current Renewal price: $" + showEditRenewalPrice : ""} 
                          />
                        </div>
                      </div>
                      <div className="relative flex justify-center mb-3 mt-6 gap-4">
                      <Button
                        buttonClasses='!bg-teal-500 !px-3 !h-8 !text-sm !gap-1'
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={(isEdit)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('save')}
                        functions={onEditRenewal}
                        // functions={() => {
                        //   if(!label){
                        //     toastr.error(t('label_field_is_required'));
                        //   }else if(!description){
                        //     toastr.error(t('description_field_is_required'));
                        //   }else{
                        //     onAddAnswer();
                        //   }
                        // }}
                        buttonHasLink={false}
                        buttonDisabled={isEdit}
                      />
                      </div>
                    </div>
                  }
                  <div className="w-full h-0.5 bg-slate-200 block relative text-center  my-4 z-[3]">
                      <div className="p-2 bg-white  text-black absolute -top-[14px] left-[46%] right-0 z-[2] w-12 flex items-center justify-center text-xs">OR</div>
                  </div>
                  <div className="grid grid-cols-3 gap-4  mt-4">
                    <div className="relative col-span-3">
                      <div className="grid sm:grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <div className={"font-medium text-slate-500 mb-1 !text-xs"}>{t("renewal_date")}
                          <span className="text-[8px] text-red-500 ml-0.5"><i className="fa-solid fa-asterisk"></i></span>
                          </div>
                          <Flatpickr
                            ref={flatPicker}
                            className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                            placeholder={t("enter_renewal_date")}
                            value={date}
                            options={{
                              dateFormat: "Y-m-d",
                              minDate: new Date(),
                              timezone: "America/Denver",
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                              setDate(dateStr);
                            }}
                          />
                        </div>
                        <div className="relative mb-3">
                          <Input 
                            label={t("renewal_price")} 
                            requiredDisplay={true}
                            labelClasses={"!text-xs"} 
                            inputType={"number"} 
                            inputPlaceholder={t("enter_renewal_price")} 
                            inputValue={renewalPrice} 
                            setInput={setRenewalPrice} 
                            errorType={shoeRenewalPrice ? "info" : ""} 
                            errorText={shoeRenewalPrice ? "Current Renewal price: $" + shoeRenewalPrice : ""} 
                          />
                        </div>
                      </div>
                      <div className="relative w-full">
                        <Checkbox
                          checkboxLabel={t("renewal_payment_confirmation")}
                          checkboxLableClass={""}
                          isChecked={isConfirm && isConfirm === 1 ? true : false}
                          checkboxValue={"is_confirm_renewal_payment"}
                          getCheckboxValue={(code, event) => {
                            if (event.target.checked === true) {
                              setIsConfirm(1);
                            } else {
                              setIsConfirm(2);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>                 
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button buttonClasses={isConfirm && isConfirm === 1 ? "!bg-teal-500" : "!bg-slate-200 !text-slate-600 border border-slate-300"} buttonLabelClasses="" buttonType={"button"} buttonIcon={loading ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("accept")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isConfirm && isConfirm === 1 ? false : true} />
                    <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={"fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={closeModal} buttonHasLink={false} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ManageRenewalPaymentPopup;
