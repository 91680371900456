import React from "react";
import SidebarMenuItem from "../navigation/SidebarMenuItem";
import SidebarSubMenuItem from "./SidebarSubMenuItem";
import useWindowDimensions from "../../helper/useWindowDimensions";
import { classNames } from "../../helper/classNames";

const SidebarMenuBlocks = ({ menuTitle, menuData, isMenuOpen, setIsMenuOpen, ...props }) => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      {
        !props.isHidden && 
        <div className={classNames(
          "relative py-4 space-y-2 transition-all duration-100",
          width > 1024 ? (isMenuOpen ? "px-2" : "px-4") : "px-2"
        )}>
          {width > 1024 ? 
          (!isMenuOpen && <div className={"text-xs uppercase text-slate-500 font-medium"}>{menuTitle}</div>) : 
          (<div className={"text-xs uppercase text-slate-500 font-medium"}>{menuTitle}</div>)
          }
          <div className="space-y-1">
              {/* {menuData.map((item, index) => (
                <SidebarMenuItem key={item._id} index={index} isStepMenu={item.isStepMenu} menuLink={item.menuLink} menuIcon={item.menuIcon} menuIconColor={item.menuIconColor} menuLabel={item.menuLabel} isMenuOpen={isMenuOpen} isLocked={item.isLocked}/>
              ))} */}
              {
                menuData.length>0 && menuData?.map((item, index) =>
                  item.menuIsLink ? (
                    <SidebarMenuItem key={item._id} index={index} isStepMenu={item.isStepMenu} menuLink={item.menuLink} menuIcon={item.menuIcon} menuIconColor={item.menuIconColor} menuLabel={item.menuLabel} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} isLocked={item.isLocked}/>
                  ) : (
                    <SidebarSubMenuItem key={item._id} isStepMenu={item.isStepMenu}  menuIcon={item.menuIcon}  menuIconColor={item.menuIconColor} submenuData={item.submenuData} menuLabel={item.menuLabel} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} isCollapse={item.isCollapse}/>
                  )
                ).filter((item) => item)
              }
            </div>         
        </div>
      }
      
    </>
  );
};

export default SidebarMenuBlocks;
