import React, { useEffect, useRef, useState } from "react";
import Image from "../../elements/Image";
import Button from "../../form/Button";
import ResourcesContent from "./ResourcesContent";
import { useParams } from "react-router-dom";
import { postData } from "../../../services/api";
import moment from "moment";
import { setTitle } from "../../../helpers/MetaTag";
import { API_URL } from "../../../config/host";
import { tinymceApiKey } from "../../../helpers";
import { Editor } from "@tinymce/tinymce-react";
import "./Custom.css";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ResourceEnquiryPopup from "../../popup/ResourceEnquiryPopup";

const ResourceDetailsContent = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Resource Details");
  const { i18n,t } = useTranslation();
  const { alias } = useParams();
  const [details, setDetails] = useState({});
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [resourceEnquiryPopup,setResourceEnquiryPopup] = useState(false);

  // useEffect(() => {
  //   if (editorRef.current) {
  //     tinymce.init({
  //       menubar: false,
  //       readonly: 1,
  //       height: 3000

  //      });
  //   }

  //   return () => {
  //     // Clean up TinyMCE instance when the component unmounts
  //     if (editorRef.current) {
  //       tinymce.get(editorRef.current.id).remove();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const result = await postData("blog/details", {
          alias: alias,
        });
        if (result.data) {
          setDetails(result.data);
          setTitle(
            "Succession Planning Software for your Business with Succession Now | " +
              details?.name
          );
          if(result?.data?.status === 2){
            return navigate("/");
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [alias]);

  return (
    <>
      <section className="relative w-full bg-white">
        <div className="relative w-full max-w-[1400px] mx-auto h-96 md:h-[500px] xl:h-[800px] overflow-hidden after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-1/2 after:bg-gradient-to-b after:from-black/50 ">
          <Image
            src={details?.image}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover !transition-all !duration-300 group-hover:scale-110"}
            popupSize={"max-w-3xl"}
          />
        </div>
        <div className="max-w-[1400px] mx-auto sm:px-5">
          <div className="bg-white relative -mt-10 sm:-mt-32 p-5 lg:p-10 rounded-t-3xl">
            <div className="text-xl lg:text-3xl font-bold text-slate-800 mb-2 leading-tight">
              {details?.name}
            </div>
            <div className="mb-2 sm:mb-8 text-xs sm:text-sm md:text-base font-light font-italic text-slate-400">
              {details && details?.created_at
                ? moment(new Date(details?.created_at)).format("Do MMM YYYY")
                : "N/A"}
            </div>
            <div className="mb-8 text-base font-light space-y-4 custom-editor -mx-4">
              {/* <div dangerouslySetInnerHTML={{__html: details?.description}}/> */}
              <Editor
                apiKey={tinymceApiKey()}
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={details?.description}
                // name={'description'}
                plugins={["autoresize"]}
                init={{
                  menubar: false,
                  readonly: 1,
                  height: 3000,
                }}
                // inline={true}
                disabled={true}
                wrapperClassName="wrapperClassName"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
              
            </div>
            <div className="!mt-auto flex flex-wrap gap-2">
              <Button
                buttonClasses={
                  "!justify-start !bg-dark-teal-400 hover:!bg-dark-teal-600 !text-sm group-hover:gap-4"
                }
                buttonIcon={"fa-regular fa-arrow-right-long"}
                buttonIconPosition={"right"}
                buttonLabel={t('watch_the_demo')}
                buttonLabelClasses={
                  "!text-xs uppercase !font-medium tracking-tighter"
                }
                buttonHasLink={false}
                functions={()=> {
                  setResourceEnquiryPopup(true);

                }}
              />
            </div>      
          </div>
        </div>
      </section>
      {details?._id ? <ResourcesContent notId={details._id} /> : ""}
      {
        resourceEnquiryPopup && 
        <ResourceEnquiryPopup
          isOpen={resourceEnquiryPopup}
          setIsOpen={(val) => setResourceEnquiryPopup(val)}
          data={''}
          getActionValue={(obj) => {
            // setActionValue(obj);
            // setIsUpdate(true);
          }}
        ></ResourceEnquiryPopup>
      }
    </>
  );
};

export default ResourceDetailsContent;
