import React, { useState, useEffect } from 'react';
import { setTitle } from "../helpers/MetaTag";
import ButtonFile from '../components/form/ButtonFile';
import Table from '../components/elements/table/Table';
import Button from '../components/form/Button';
import Select from '../components/form/Select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from "../helpers";
import toastr from 'toastr';
import moment from 'moment';
import NoDataFound from '../components/NoDataFound';
import Searching from '../components/Searching';

import { postData } from '../services/api';
import { useTranslation } from "react-i18next";
import { CSVLink, CSVDownload } from "react-csv";
import Papa from 'papaparse';
import ManageEmployeePopup from '../components/popup/ManageEmployeePopup';
import DeletePopup from '../components/DeletePopup';
import ReactPaginate from 'react-paginate';
// import InputFile from "../components/form/InputFile";
// import ImageUploader from "../components/form/ImageUploader";
const CompanyEmployees = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Company Employees");
  const [loading, setLoading] = useState(true);
  const { i18n,t } = useTranslation();
  const role =  localStorage.getItem("role");
  const time = new Date().getTime();
  const [limit,setLimit] =  useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [position, setPosition] = useState([]);
  const [sortKey,setSortKey]= useState('created_at');
  const [sortValue,setSortValue] = useState(-1);
  const [sortQuery,setSortQuery] = useState('');
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [isUpdateFile,setIsUpdateFile] = useState(false);
  const [actionValue,setActionValue] = useState({});
  const [enabledManageEmployeePopup, setEnabledManageEmployeePopup] = useState(false);
  const [currentId,setCurrentId] =  useState('')
  const [enabledDeletePopup, setenabledDeletePopup] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const sampleExcelData =  [
    ["firstname", "lastname", "email","position"]
  ];

  const [selectedFile, setSelectedFile] = useState(null);
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: async (res) => {
        // Update state with parsed CSV data
        //setCsvData(result.data);
        try {
          const result = await postData("employee/create",{
            result: res?.data,
          });
          if(result.data) {
            setIsUpdateFile(true);
          }
          setTotalDataCount(result.count);          
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err.message);
        }
        
        // You can customize the CSV file name here
        //setCsvFileName(file.name);
      },
      header: true, // Set to true if your CSV has a header row
    });
  };

  useEffect(()=>{
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          isBoardMember : 2
        });
        if (result.data) {
          setPosition(result.data?.sort((a, b) => a.name.localeCompare(b.name)));
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
  },[])

  useEffect(() => {
    const loadTempData = async () => {
      setLoading(true);
      try {
        const result = await postData("employee/list",{
          limit: limit,
          offset: offset,
          sortQuery:sortQuery,
        });
        if(result.data) {
          setCsvData(result.data);
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadTempData();
}, [limit, offset,sortQuery,isUpdateFile,actionValue]);

useEffect(() => {
  setNoOfPage(Math.ceil(totalDataCount / limit));
  const startItem = Number((totalDataCount>0)?1:0);
  const endItem = Math.min(startItem + limit - 1, totalDataCount);
  setStartDataCount(startItem);
  setEndDataCount(endItem)
}, [limit, totalDataCount]);

const handelUpdate = async (id, val, field, index) => {
  try {
    setCsvData(
      csvData.map((it, idx) => {
        if (idx === index) {
          return { ...it, [field]: val };
        } else {
          return it;
        }
      })
    );
    const result = await postData("employee/update", {
      id: id,
      [field]: val !== "" ? val : null,
    });
    if (result.status && result.status === 200) {
      toastr.success(result.message);
    } else {
      toastr.error(result.message);
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

  const tableHeadData = [
    {_id:1, width:40, name:t('first_name'), value:'firstname', align:'left', isSort:true, isFilter:false},
    {_id:2, width:15, name:t('last_name'), value:'lastname', align:'left', isSort:true, isFilter:false},
    {_id:3, width:15, name:t('email'), value:'email', align:'left', isSort:true, isFilter:false},
    {_id:4, width:20, name:t('position'),value:'created_at', align:'left', isSort:false, isFilter:false}, 
    {_id:5, width:20, name:t('date'),value:'created_at', align:'left', isSort:false, isFilter:false},   
    //{_id:6, width:10, name:t('action'),value:'', align:'right', isSort:false, isFilter:false},
  ]

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };

  const handleOnDragEnd = async (result) => {
    const items = reorder(
      csvData,
      result.source.index,
      result.destination.index
    );  
    setCsvData(items);
    
    const res = await postData("position/ordering", { 
      items : items,  
    });
    if (res.status && res.status === 200) {        
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  }
  
  // const file = {
  //   name:"File chosen", 
  //   image:"https://images.unsplash.com/photo-1550791871-0bcd47c97881?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cGF0aWVudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
  // }

  useEffect(() => {
    if(actionValue && actionValue.button_type === 'delete'){
      const loadDeleteRecord = async () => {
        setIsUpdate(false)
        try {
          const statusData = await postData("employee/delete", {
            id:actionValue?.row_id,
          });
          if (statusData.data){
            const index = csvData.findIndex((item) => item._id === actionValue?.row_id);
            if(index !== -1){
              csvData.splice(index, 1);
              setCsvData(
                csvData.map((it) => { 
                  return it;
                })
              )
            }
            setIsUpdate(true)
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
            toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    }
  },[actionValue])

  const downloadSampleFile = async () => {
    try {
      const res = await postData("download/sample-csv", {});
      if (res) {      
        const blob = new Blob([res], { type: 'text/csv' });
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "succession-employee-"+time+".csv"; 
        downloadLink.click();
        URL.revokeObjectURL(blobUrl);
      } else {
        toastr.error(res.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  }

  
  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t('company_employees')}</div>  
      <div className="sm:ml-auto flex justify-start sm:justify-end items-center gap-2 flex-wrap">
        <Button
          buttonClasses=""
          buttonLabelClasses=""
          buttonType={"button"}
          buttonIcon={"fa-light fa-arrow-right-to-arc"}
          buttonIconPosition={"left"}
          buttonLabel={t('add_employee')}
          buttonHasLink={false}
          functions={()=> setEnabledManageEmployeePopup(true)}
        />
        <Button
          buttonClasses=""
          buttonLabelClasses=""
          buttonType={"button"}
          buttonIcon={"fa-light fa-download"}
          buttonIconPosition={"left"}
          buttonLabel={t('sample_excel_file')}
          buttonHasLink={false}
          functions={()=> downloadSampleFile()}
        />
        {/* <CSVLink 
        data={sampleExcelData}
        filename={time+"-sample.csv"}
        className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer"}
        separator=","
        ><i className={"fa-fw fa-light fa-download"}></i> Sample Excel file</CSVLink>    */}

        <div className="relative cursor-pointer w-full sm:w-auto">
            <div className="flex flex-col items-center justify-center gap-2 border border-dashed border-teal-500 text-teal-500 bg-transparent rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 mx-auto cursor-pointer">
              <label htmlFor="fileInput" className="text-xs sm:text-sm font-medium whitespace-nowrap cursor-pointer">
                Choose File <i className="fa-solid fa-plus"></i>
              </label>
              <input
                id="fileInput"
                type="file"
                className="hidden cursor-pointer"
                onChange={handleFileChange}
              />
              {selectedFile && (
                <div className="mt-4">
                  <p className="text-lg font-semibold">Selected File:</p>
                  <p>{selectedFile.name}</p>
                </div>
              )}
            </div>
        </div>     
        {/* <div className=''>
          <input type="file" accept=".csv" onChange={handleFileChange} />
        </div> */}
      </div>   
    </div>
    <div className="relative flex justify-between items-center mb-4">
          
    </div>
    <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px] mb-4">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                {t('first_name')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">
                {t('last_name')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">
                {t('email')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">
                {t('position')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">
                {t('date')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">
                {t('action')}
              </th>
            </tr>
          </thead>
          {totalDataCount > 0 ? (
            <tbody className="text-slate-700 text-sm font-light bg-white">
              {csvData.map((items, index) => {
                let firstName = '';
                let lastName = '';
                if(!items?.first_name){
                  firstName = items?.name.split(/\s+/)[0];
                  lastName = items?.name.split(/\s+/)[1];
                }else{
                  firstName = items?.first_name;
                  lastName = items?.last_name;
                }
                return(
                  <tr className="border-b border-slate-200 group" key={index}>
                  <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle" >
                    {firstName}
                  </td>
                  <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle" >
                    {lastName}
                  </td>
                  <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle" >
                    {items?.email}
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <Select
                      xPlacement={"bottomLeft"}
                      selectedValue={items?.designation}
                      dropdownClass={"!w-60"}
                      dropdownButtonLabelClass={"!font-bold"}
                      search={true}
                      dropdownData={[
                        { _id: "", label: t('select_position'), value: "" },
                        ...position.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                      ]}
                      dropdownOptionBold={true}
                      getSelectedValue={(e) =>
                        handelUpdate(
                          items._id,
                          e._id,
                          "position",
                          index
                        )
                      }
                    />
                  </td>
                  <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle" >
                    {(items && items?.created_at)?moment(new Date(items.created_at)).format('Do MMM YYYY'):'N/A'}
                  </td>
                  <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle" >
                    <Button
                      buttonClasses={"!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !w-4 !h-4 !text-rose-500"}
                      buttonType={"button"}
                      buttonIcon={"fa-regular fa-trash-can"}
                      buttonIconPosition={"left"}
                      buttonHasLink={false}
                      functions={() => {
                        setCurrentId(items?._id)
                        setenabledDeletePopup(true);
                        setIsUpdate(false);
                      }}
                    />
                  </td>
                </tr>
                )
              })}
            </tbody>
          ):(
            <tbody className="text-slate-700 text-sm font-light bg-white">
              <tr className="border-b border-slate-200 group" >
                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle" colspan={6}>
                  {
                    loading?<Searching label={t('searching')}/>:<NoDataFound label={t('no_data_found')}/>
                  }
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    {/* <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className="w-full rounded-md shadow">
          <Table 
          tableData={csvData} 
          tableHeadData={tableHeadData}
          totalRecord={totalDataCount}
          loading={loading}
          getSortValue={setSortValue}
          getSortKey={setSortKey}
          //editUrl={'/edit-user'}
          isStatusUpdate={true}
          isDeleteEnabled={true}
          getActionValue={(obj)=> setActionValue(obj)}
          //othersAction = {othersAction}
          //loginAs ={'/login-as'}
          isDraggable={false}
          //extraData={extraData}
          />
      </div>
    </DragDropContext> */}
    {
      noOfPage > 1 ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>
          <ReactPaginate 
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" 
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick} 
            pageRangeDisplayed={3} 
            pageCount={noOfPage} 
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      ):(
        <div className="flex justify-center sm:justify-between my-4">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}          
        </div>
      )
    }
    {enabledManageEmployeePopup && (
      <ManageEmployeePopup
        isOpen={enabledManageEmployeePopup}
        setIsOpen={(val) => setEnabledManageEmployeePopup(val)}
        getActionValue={(obj) => {
          setActionValue(obj);
        }}
      ></ManageEmployeePopup>
    )}
    {
      enabledDeletePopup &&
      <DeletePopup
        isOpen={enabledDeletePopup}
        setIsOpen={(val) => setenabledDeletePopup(val)}
        currentId={currentId}
        getActionValue={(obj) => {
          setActionValue(obj)
          setenabledDeletePopup(false);
          setCurrentId('')
        }}
      />
    }
    </>
  );
};

export default CompanyEmployees;