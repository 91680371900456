import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import Table from "../../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import BuyPlanPopup from "../../components/popup/BuyPlanPopup";
import toastr from "toastr";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";

const Companies = ({}) => {
  setTitle("Succession Planning Software for your Business with Succession Now | Companies");
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [emailVerified, setEmailVerified] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [accountType, setAccountType] = useState("");

  const othersAction = {
    type: "button",
    link: "/",
    label: t("payment_status"),
    icon: "fa-hand-holding-dollar",
    isVisabled: 2,
  };
  const [enabledBuyPlanPopup, setEnabledBuyPlanPopup] = useState(false);
  const [companyData, setCompanyData] = useState({});

  const capitalizeString = (str) => {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  };

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("usertype/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setCompanies(
              companies.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("usertype/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = companies.findIndex((item) => item._id === actionValue?.row_id);
            if (index !== -1) {
              companies.splice(index, 1);
              setCompanies(
                companies.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (actionValue && actionValue.button_type === "others") {
      setEnabledBuyPlanPopup(true);
      setCompanyData(actionValue);
    }
  }, [actionValue]);

  useEffect(() => {
    // List Data
    const loadList = async () => {
      setLoading(true);
      try {
        const result = await postData("usertype/list", {
          keyword: keyword,
          limit: limit,
          offset: keyword ? 0 : offset,
          sortQuery: sortQuery,
          email_verified: emailVerified,
          status: status,
          accountType: accountType,
          code: "customer-admin",
        });
        if (result.data) {
          setCompanies(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                email: value?.email,
                rowData: [
                  { _id: 1, width: 40, type: "user", data: value?.name, image: value?.image },
                  { _id: 2, width: 15, type: "text", data: value?.email },
                  { _id: 3, width: 20, type: "text", data: value && value?.created_at ? moment(new Date(value?.created_at)).format("Do MMM YYYY") : "N/A" },
                  { _id: 4, width: 15, type: "status", statusLabel: value?.email_verified && value?.email_verified === 1 ? t("verified") : t("not_verified"), statusType: value?.email_verified && value?.email_verified === 1 ? "success" : "warning" },
                  { _id: 5, width: 15, type: "text", data: value?.promo_code },
                  { _id: 6, width: 15, type: "status", statusLabel: value?.subscription_details.length > 0 && value?.subscription_details[0].status === 1 ? t("paid") : t("unpaid"), statusType: value?.subscription_details.length > 0 && value?.subscription_details[0].status === 1 ? "success" : "danger" },
                  { _id: 7, width: 15, type: "text", data: value?.account_type ? capitalizeString(value?.account_type) : "", isColor: "" },
                  { _id: 8, width: 15, type: "status", statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"), statusType: value?.status && value?.status === 1 ? "success" : "warning" },
                  {
                    _id: 9,
                    width: 10,
                    type: "action",
                    statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
                    statusType: value?.status && value?.status === 1 ? "success" : "warning",
                    data: [{ others: value?.subscription_details && value?.subscription_details.length > 0 ? 1 : 2 }],
                  },
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadList();
  }, [keyword, limit, offset, sortQuery, emailVerified, status, accountType, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number((totalDataCount>0)?1:0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };

  const tableHeadData = [
    { _id: 1, width: 40, name: t("name"), value: "name", align: "left", isSort: true, isFilter: false },
    { _id: 2, width: 15, name: t("email"), value: "email", align: "left", isSort: true, isFilter: false },
    { _id: 3, width: 20, name: t("created_date"), value: "created_at", align: "left", isSort: false, isFilter: false },
    { _id: 4, width: 15, name: t("email_verified"), value: "email_verified", align: "left", isSort: false, isFilter: false },
    { _id: 5, width: 15, name: t("activation_code"), value: "promo_code", align: "left", isSort: true, isFilter: false },
    { _id: 6, width: 15, name: t("paid") + "/" + t("unpaid"), value: "industry", align: "left", isSort: true, isFilter: false },
    { _id: 7, width: 15, name: t("account_type"), value: "account_type", align: "left", isSort: true, isFilter: false },
    { _id: 8, width: 15, name: t("status"), value: "status", align: "left", isSort: false, isFilter: false },
    { _id: 9, width: 10, name: t("action"), value: "", align: "right", isSort: false, isFilter: false },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(companies, result.source.index, result.destination.index);
    setCompanies(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">{t("companies")}</div>
      </div>
      <div className="relative flex gap-3 items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
        <div className="relative">
          <Select
            xPlacement={"bottomLeft"}
            dropdownButtonClass={"!bg-white"}
            dropdownClass={"w-auto"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative w-48">
            <Search placeholder={t("search")} setInput={setKeyword} />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"bottomRight"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass={"w-48"}
              transitionClass={"!w-full"}
              selectedValue={emailVerified}
              dropdownData={[
                { _id: "", label: t("select_email_verification") },
                { _id: 1, label: t("verified"), value: 1 },
                { _id: 2, label: t("not_verified"), value: 2 },
              ]}
              getSelectedValue={(e) => setEmailVerified(e.value)}
            />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"bottomRight"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass={"w-48"}
              transitionClass={"!w-auto"}
              selectedValue={status}
              dropdownData={[
                { _id: "", label: t("select_status") },
                { _id: 1, label: t("active"), value: 1 },
                { _id: 2, label: t("deactivated"), value: 2 },
              ]}
              getSelectedValue={(e) => setStatus(e.value)}
            />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"bottomRight"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass={"w-48"}
              transitionClass={"!w-auto"}
              selectedValue={accountType}
              dropdownData={[
                { _id: "", label: t("select_account_type"), value: "" },
                { _id: "business", label: capitalizeString("business"), value: "business" },
                { _id: "demonstration", label: capitalizeString("demonstration"), value: "demonstration" },
                { _id: "consultant", label: capitalizeString("consultant"), value: "consultant" },
                { _id: "developer", label: capitalizeString("developer"), value: "developer" },
                { _id: "training", label: capitalizeString("training"), value: "training" },
              ]}
              getSelectedValue={(e) => setAccountType(e.value)}
            />
          </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={companies}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            //editUrl={'/edit-company'}
            isStatusUpdate={true}
            isDeleteEnabled={true}
            getActionValue={(obj) => setActionValue(obj)}
            othersAction={othersAction}
            isDraggable={false}
            isUser={"/users"}
            loginAs={"/login-as"}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount}/>
      ):(
        <div className="flex justify-center sm:justify-between my-4">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}  
        </div>
      )}
      {enabledBuyPlanPopup && (
        <BuyPlanPopup
          isOpen={enabledBuyPlanPopup}
          setIsOpen={(val) => setEnabledBuyPlanPopup(val)}
          data={companyData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={"predictors"}
        ></BuyPlanPopup>
      )}
    </div>
  );
};
export default Companies;
