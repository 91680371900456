import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Search from '../components/form/Search';
import Table from '../components/elements/table/Table';
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from "../helpers";
import toastr from 'toastr';
import ManageRolePopup from "../components/popup/ManageRolePopup";
//import CrmTab from "../shared/CrmTab";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../components/Common/Pagination";

const Staff = () => {
    setTitle("Succession Planning Software for your Business with Succession Now | Sales & Consultants");
    const { i18n,t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [keyword,setKeyword] = useState('');
    const [limit,setLimit] =  useState(10);
    const [offset, setOffset] = useState(0);
    const [sortKey,setSortKey]= useState('created_at');
    const [sortValue,setSortValue] = useState(-1);
    const [sortQuery,setSortQuery] = useState('');
    const [emailVerified,setEmailVerified] = useState('');
    const [position,setPosition] = useState('');
    const [noOfPage, setNoOfPage] = useState(0);
    const [staff,setStaff] = useState([]);
    const [users,setUsers] = useState([]);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [startDataCount,setStartDataCount] = useState(0);
    const [endDataCount,setEndDataCount] = useState(0);
    const [roles,setRoles] = useState([]);
    const [actionValue,setActionValue] = useState({});
    const [isUpdate,setIsUpdate] = useState(false);

    const [enabledRoleManagePopup, setEnabledRoleManagePopup] = useState(false);
    const [roleInfo,setRoleInfo] = useState({});

    const extraData = [
      {
        _id: 1,
        label: t('password_generate_mail'),
        icon: 'fa-envelope',
        link: '/',
        type: 'button',
        standout: false,
        buttonType: 'generate-password',
        isVisabled:1,
        ordering: 2
      },
      {
        _id: 1,
        label: t('role_manage'),
        icon: 'fa-users',
        link: '/',
        type: 'button',
        standout: false,
        buttonType: 'role-manage',
        isVisabled:1,
        ordering: 3
      }
    ]

    useEffect(() => {
        if(sortKey && sortValue){
            setSortQuery({[sortKey]:sortValue})
        }else{
            setSortQuery('')
        }
    }, [sortKey,sortValue]);

    useEffect(()=>{
      const loadRoles = async () => {
        try {
          const result = await postData("role/list", {
            sortQuery : {ordering:1},
            code : ['consultant','sales-admin','sales-staff','appointment-setter']
          });
          if (result.data) {
            setRoles(result.data)
          }
        } catch (err) {
          console.log(err.message);
        }
      };
      loadRoles();
    },[])

    useEffect(() => {
        // Email template List
        const loadEmailTemplate = async () => {
          setLoading(true);
          try {
            const result = await postData("usertype/list",{
              isStaff : 1,
              //code:['consultant','sales-admin','sales-staff','appointment-setter'],
              keyword: keyword,
              limit: limit,
              offset: (keyword)?0:offset,
              sortQuery:sortQuery,
              email_verified:emailVerified,
              position:position
            });
            if(result.data) {
                setUsers(result.data);
                setStaff(
                  result.data.map((value, key) => {
                    let sales = value?.commission.filter((item) => item.code === 'sales-staff');
                    let consultant = value?.commission.filter((item) => item.code === 'consultant');
                    let appointmentSetter = value?.commission.filter((item) => item.code === 'appointment-setter');
                    let salesAdmin = value?.commission.filter((item) => item.code === 'sales-admin');
                    let roleDetails = value?.role_details.map((item) => item?.name);
                    let roles = '';
                    if(Array.isArray(roleDetails) && roleDetails.length>0){
                      roles = roleDetails.toString();
                    }
                    
                    return {
                      _id: value?._id,
                      email: value?.email,
                      rowData:[
                          { _id:1, width:15, type:'text', data:value?.first_name }, 
                          { _id:2, width:40, type:'text', data:value?.last_name },
                          { _id:5, width:15, type:'text', data:roles },
                          { _id:3, width:15, type:'text', data:value?.email },
                          { _id:4, width:15, type:'text', data:value?.phone },
                          // { _id:4, width:15, type:'text', data:`Sales Commission:${(sales[0].commission)?sales[0].commission:0}%,Consultant Commission:${(consultant[0].commission)?consultant[0].commission:0}%,Appointment Setter Commission:${(appointmentSetter[0].commission)?appointmentSetter[0].commission:0}%,Sales Admin Commission:${(salesAdmin[0].commission)?salesAdmin[0].commission:0}%`},
                          { _id:6, width:20, type:'text', data:(value && value?.created_at)?moment(new Date(value?.created_at)).format('Do MMM YYYY'):'N/A'},
                          { _id:7, width:15, type:'status', statusLabel:(value?.email_verified && value?.email_verified === 1)?t('verified'):t('not_verified'), statusType:(value?.email_verified && value?.email_verified === 1)?'success':'warning'},
                          { _id:8, width:15, type:'status', statusLabel:(value?.status && value?.status === 1)?t('active'):t('deactivated'), statusType:(value?.status && value?.status === 1)?'success':'warning'},
                          { 
                            _id:9, 
                            width:10, 
                            type:'action',
                            statusLabel: (value?.status && value?.status === 1)?t('active'):('deactivated'),
                            statusType: (value?.status && value?.status === 1)?'success':'warning',
                            data:[
                              {'generate-password':1},
                              {'role-manage':1}
                            ]
                          }
                      ]
                    }
                  })
                )
            }
            setTotalDataCount(result.count);
            setLoading(false);
          } catch (err) {
            setLoading(false);
            console.log(err.message);
          }
        };
        loadEmailTemplate();
    }, [keyword, limit, offset,sortQuery,emailVerified,position,isUpdate]);

    useEffect(() => {
      setNoOfPage(Math.ceil(totalDataCount / limit));
      const startItem = Number((totalDataCount>0)?1:0);
      const endItem = Math.min(startItem + limit - 1, totalDataCount);
      setStartDataCount(startItem);
      setEndDataCount(endItem)
      if(keyword){ setOffset(0) }
    }, [limit, totalDataCount,keyword]);
    
    const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
    };

    useEffect(() => {
      if(actionValue && actionValue.button_type === 'status'){
        const loadStatusChange = async () => {
          setIsUpdate(false)
          try {
            const statusData = await postData("usertype/status-change", {
              id:actionValue?.row_id,
              status:actionValue?.current_status,
            });
            if (statusData.data){
              setStaff(
                staff.map((it) => { 
                  if(it?._id === statusData.data?._id){
                    return {
                      ...it, 
                      status:statusData.data?.status,
                    }
                  } else {
                    return it;
                  }
                })
              )
              setIsUpdate(true)
            } else {
              toastr.error(statusData.message);
            }
          } catch (error) {
              toastr.error(error.message);
          }
        };
        loadStatusChange();
      }else if(actionValue && actionValue.button_type === 'delete'){
        const loadDeleteRecord = async () => {
          setIsUpdate(false)
          try {
            const statusData = await postData("usertype/delete", {
              id:actionValue?.row_id,
            });
            if (statusData.data){
              const index = staff.findIndex((item) => item._id === actionValue?.row_id);
              if(index !== -1){
                staff.splice(index, 1);
                setStaff(
                  staff.map((it) => { 
                    return it;
                  })
                )
              }
              setIsUpdate(true)
            } else {
              toastr.error(statusData.message);
            }
          } catch (error) {
              toastr.error(error.message);
          }
        };
        loadDeleteRecord();
      }else if(actionValue && actionValue.button_type === 'generate-password'){
        const loadResendInvitation = async () => {
          try {
            const othersData = await postData("usertype/generate-password", {
              id:actionValue?.row_id
            });
            if(othersData.data){
              setActionValue({});
              toastr.success('Email resent.Please make sure to check your spam email.');
            }else{
              toastr.error(othersData.message);
            }
          } catch (error){
              toastr.error(error.message);
          }
        };
        loadResendInvitation();
      }else if(actionValue && actionValue.button_type === 'role-manage'){
        setEnabledRoleManagePopup(true)
        const index = users.findIndex((item) => item._id === actionValue?.row_id);
        setRoleInfo({
          _id :  actionValue?.row_id,
          role : users[index]?.role,
        })
        setIsUpdate(false);
      }else if(actionValue && actionValue.button_type === 'edit'){
        setIsUpdate(true);
      }
      
    }, [actionValue]);

    const tableHeadData = [
        {_id:1, width:15, name:t('first_name'), value:'first_name', align:'left', isSort:true, isFilter:false},
        {_id:2, width:40, name:t('last_name'), value:'last_name', align:'left', isSort:true, isFilter:false},
        {_id:5, width:15, name:t('role'), value:'role', align:'left', isSort:true, isFilter:false},
        {_id:3, width:15, name:t('email'), value:'email', align:'left', isSort:true, isFilter:false},
        {_id:4, width:15, name:t('phone_number'), value:'phone', align:'left', isSort:true, isFilter:false},
        // {_id:5, width:15, name:'Position', value:'position', align:'left', isSort:true, isFilter:false},
        {_id:6, width:20, name:t('created_date'),value:'created_at', align:'left', isSort:false, isFilter:false},
        {_id:7, width:15, name:t('email_verified'), value:'email_verified', align:'left', isSort:false, isFilter:false},
        {_id:8, width:15, name:t('status'), value:'status', align:'left', isSort:false, isFilter:false},
        {_id:9, width:10, name:t('action'),value:'', align:'right', isSort:false, isFilter:false},
    ]

    const handleOnDragEnd = async (result) => {
      const items = reorder(
        staff,
        result.source.index,
        result.destination.index
      );  
      setStaff(items);
      
      const res = await postData("position/ordering", { 
        items : items,  
      });
      if (res.status && res.status === 200) {        
        toastr.success(res.message);
      } else {
        toastr.error(res.message);
      }
    }

    return (
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        {/* <div className="relative ml-auto flex justify-start items-center">
            <CrmTab xPlacement={'bottomLeft'}/>
        </div> */}
        <div className="relative flex justify-between items-center mb-3">
          <div className="text-2xl font-bold">{t('sales_&_consultants')}</div> 
          <div className="sm:ml-auto flex justify-end items-center">
              <Button 
                buttonType={'button'} 
                buttonIcon={'fa-light fa-plus'} 
                buttonIconPosition={'left'} 
                buttonLabel={t('add')} 
                buttonHasLink={true}
                buttonLink={'/add-staff'}
                />
          </div>
        </div>
        <div className="relative flex gap-3 items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
            <div className="relative">
                <Select 
                    xPlacement={'left'} 
                    dropdownButtonClass={'!bg-white'} 
                    selectedValue={limit} 
                    dropdownData={[
                      { _id: 1000000, label: t('all_items'),value: 1000000 }, 
                      ...[{ _id: 10, label: '10 '+t('items'), value: 10 },
                          { _id: 20, label: '20 '+t('items'), value: 20 },
                          { _id: 30, label: '30 '+t('items'), value: 30 },
                          { _id: 50, label: '50 '+t('items'), value: 50 },
                          { _id: 100, label: '100 '+t('items'), value: 100 }]
                    ]}
                    getSelectedValue={(e) => setLimit(e.value)}
                />
            </div>
            <div className="relative w-48">
                <Search placeholder={t('search')} setInput={ setKeyword } />
            </div>
            <div className="relative w-48">
                <Select 
                  xPlacement={'right'} 
                  dropdownButtonClass={'!bg-white'} 
                  dropdownClass="w-48"
                  transitionClass={'!w-auto'}
                  selectedValue={emailVerified} 
                  dropdownData={[
                      { _id: '', label: t('select') },
                      { _id: 1, label: t('verified'), value: 1 },
                      { _id: 2, label: t('not_verified'), value: 2 },
                  ]}
                  getSelectedValue={(e) => setEmailVerified(e.value)}
                />
            </div>
            <div className="relative w-48">
              <Select 
                xPlacement={'right'} 
                dropdownButtonClass={'!bg-white'} 
                dropdownClass="w-48"
                transitionClass={'!w-auto'}
                selectedValue={emailVerified} 
                search={true}
                dropdownData={[
                    { _id: '', label: t('select_position') },
                    ...roles.map((item) => ({
                      _id: item?._id, 
                      label: item?.name,
                      value:item?.code
                  }))
                ]}
                getSelectedValue={(e) => setPosition(e._id)}
                />
            </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow">
            <Table 
            tableData={staff} 
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            editUrl={'/edit-staff'}
            isStatusUpdate={true}
            isDeleteEnabled={true}
            isDraggable={false}
            getActionValue={(obj)=> setActionValue(obj)}
            extraData={extraData}
            loginAs ={'/login-as'}
            />
        </div>
        </DragDropContext>
        {
          noOfPage > 1 ? (
            <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount}/>
          ):(
            <div className="flex justify-center sm:justify-between my-4">
              {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}  
            </div>
          )
        }
        {          
          enabledRoleManagePopup &&
          <ManageRolePopup
            isOpen={enabledRoleManagePopup}
            setIsOpen={(val) => setEnabledRoleManagePopup(val)}
            data={ roleInfo }
            getActionValue={(obj) => {
              setActionValue(obj)  
              setEnabledRoleManagePopup(false);
              //setCompanyInfo({})   
            }}
          />
        }
      </div>
    );
}

export default Staff;