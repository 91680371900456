import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";

import { postData } from "../services/api";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import Button from "../components/form/Button";
//import { jsPDF } from "jspdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PolicyReport from "./pdf/PolicyReport";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Policy = () => {
  setTitle("Succession Now | Succession Plan Purpose and Policy Statement");
  const { i18n, t } = useTranslation();
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);
  //const [display,setDisplay] = useState('none');
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  //const pdfRef = useRef(null);

  // const createPdf = async () => {
  //   setDisplay('block');
  //   const content = pdfRef.current;
  //   const doc = new jsPDF({
  //     orientation: "p",
  //     unit: "pt",
  //     format: "a4",
  //   });
  //   doc.html(content, {
  //     callback: function (doc) {
  //         doc.setProperties({
  //           title: "Policy Report"
  //         });
  //         doc.output('dataurlnewwindow');
  //         setDisplay('none');
  //     }
  //   });
  // }

  useEffect(() => {
    async function loadPageData() {
      setLoading(true);
      try {
        const result = await postData("page/details", {
          alias: "policy-statement",
        });
        if (result.data) {
          setPageData(result.data);
          setLoading(false);
        } else {
          console.log("Page Details message", result.message);
          setLoading(false);
        }
      } catch (error) {
        console.log("Error page details catch", error.message);
        setLoading(false);
      }
    }
    loadPageData();
  }, []);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("succession_plan_purpose_and_policy_statement")}</div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          {role && (role === "customer-admin" || role === "customer-subadmin" || role === "master-admin") && <Button buttonType={"button"} buttonIcon={"fa-light fa-edit"} buttonIconPosition={"left"} buttonLabel={t("manage_policy")} buttonHasLink={true} buttonLink={"/manage-policy"} />}
          <button type={"button"} className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer "} onClick={(e) => getDownload(e)}>
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink document={<PolicyReport data={pageData?.description} user={user} />} fileName="profile_report.pdf">
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_pdf"))}
              </PDFDownloadLink>
            </div>
          </button>
          {/* <Button
                buttonType={"button"}
                buttonIcon={"fa-light fa-file-export"}
                buttonIconPosition={"left"}
                buttonLabel={"Export "}
                functions={createPdf}
              /> */}
        </div>
      </div>
      <div className="relative bg-white shadow rounded">
        <div className="py-8 px-4">{pageData && Object.keys(pageData).length > 0 ? <div className="text-sm text-slate-600 space-y-4 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: pageData?.description }}></div> : loading ? <Searching label={t("searching")} /> : <NoDataFound label={t("no_data_found")} />}</div>
      </div>
      {/* <div style={{display:display}}>
        <div style={{width:'100%',padding:'20px',maxWidth:'600px',fontSize:'10px'}} ref={pdfRef}>
            <div 
            className="text-xs text-slate-600 space-y-4 w-100" 
            dangerouslySetInnerHTML={{__html:pageData?.description}}></div>
        </div>
      </div> */}
    </>
  );
};

export default Policy;
