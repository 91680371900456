import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DefaultSidebar from '../shared/DefaultSidebar';
import DefaultHeader from '../shared/DefaultHeader';
import useWindowDimensions from "../helper/useWindowDimensions";
import { classNames } from "../helper/classNames";

const DefaultLayout = ({getIsChange}) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div className="relative flex w-full h-screen overflow-hidden">
        <div className={classNames(
          "fixed h-screen flex transition-all duration-100 z-30", 
          width > 1024 ? (isMenuOpen ? "w-16" : "w-60") : (isMenuOpen ? "w-60 translate-x-0" :"w-60 -translate-x-full")
        )}>
          <DefaultSidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} getIsChange={getIsChange}/>
        </div>
        <div className={classNames(
          "relative w-full flex flex-col transition-all duration-100",
          width > 1024 ? (isMenuOpen ? "pl-16" : "pl-60") : ""
        )}>
          <DefaultHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
          <div className="relative w-full h-full max-h-[calc(100vh-4rem)] py-4 xl:py-6 px-4 xl:px-6 overflow-auto scroll-smooth scrollbar bg-slate-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;