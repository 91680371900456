import React, { useEffect, useState, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import Image from "../components/elements/Image";
import { Link } from "react-router-dom";
import Button from "../components/form/Button";
import LogoutPopup from "../components/LogoutPopup";

import { useSelector } from "react-redux";
import { API_URL } from "../config/host";
import { useTranslation } from "react-i18next";

const UserDropdown = ({ xPlacement, ...props }) => {
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role");
  const loginAs = localStorage.getItem("loginAs");
  const user = useSelector((state) => state.auth?.user);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const [displayImage, setDisplayImage] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [enabledLogoutPopup, setenabledLogoutPopup] = useState(false);
  //console.log('user',user);
  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  let dropdownData = [];
  role && role !== "master-admin"
    ? (dropdownData = [
        (user && user.isInvitedEmployee !== 2 && (role !== 'customer-boardmember' && role !== 'customer-executive'))?
        {
          _id: 1,
          label: t('profile'),
          icon: "fa-circle-user",
          link: "/profile",
          type: "link",
          standout: false,
        }:'',
        (user && user.isInvitedEmployee !== 2)?
        {
          _id: 2,
          label: t('change_password'),
          icon: "fa-key",
          link: "/change-password",
          type: "link",
          standout: false,
        }:'',
        (role === 'customer-admin' || role === 'customer-subadmin')?
        {
          _id:4, 
          label:t('competencies'), 
          icon:'fa-circle-waveform-lines', 
          link:'/competencies-alt', 
          type:'link', 
          standout:false
        }:'',
        // !loginAs?
        // {
        //   _id: 2,
        //   label: "CRM",
        //   icon: "fa-users",
        //   link: "/users",
        //   type: "link",
        //   standout: false,
        // }:'',
        // {
        //   _id: 3,
        //   label: "CMS",
        //   icon: "fa-camera-retro",
        //   link: "/positions",
        //   type: "link",
        //   standout: false,
        // },
        // (role === 'customer-admin')?
        // {_id:4, label: 'Plan & Pricing', icon:'fa-car', link:'/plans', type:'link', standout:false}:'',
        (role === "customer-admin" || role ==='customer-subadmin')? 
        {
          _id: 5,
          label: t('Billing'),
          icon: "fa-money-simple-from-bracket",
          link: "/subscriptions",
          type: "link",
          standout: false,
        }
        :"",
        // role === "customer-admin"
        //   ? {
        //       _id: 9,
        //       label: "Settings",
        //       icon: "fa-gear",
        //       link: "/settings",
        //       type: "link",
        //       standout: false,
        //     }
        //   : "",
        {
          _id: 10,
          label: t('logout'),
          icon: "fa-arrow-right-from-arc",
          link: "/",
          type: "button",
          standout: false,
        },
      ])
    : (dropdownData = [
        {
          _id: 1,
          label: t('profile'),
          icon: "fa-circle-user",
          link: "/profile",
          type: "link",
          standout: false,
        },
        {
          _id: 2,
          label: t('change_password'),
          icon: "fa-key",
          link: "/change-password",
          type: "link",
          standout: false,
        },
        // {_id:4, label: 'Permission', icon:'fa-lock', link:'/permission', type:'link', standout:false},
        {
          _id: 10,
          label: t('logout'),
          icon: "fa-arrow-right-from-arc",
          link: "/",
          type: "button",
          standout: false,
        },
      ]);

  useEffect(() => {
    if(role === 'content-manager'){
      setDisplayName(user?.name);
    }else{
      setDisplayName(user?.company_name);
    }
    setDisplayImage(API_URL+user?.image);   
  }, [user]);

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div className="relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200">
                <div className="w-8 h-8 min-w-[32px] overflow-hidden rounded-full">
                  <Image
                    src={displayImage}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                    id={"headerImg"}
                  />
                </div>
                <div className="text-sm font-semibold text-slate-500 mx-2">
                  {displayName}
                </div>
                <div className="text-xs text-slate-400">
                  <i
                    className={
                      "fa-light fa-fw " +
                      (open ? "fa-chevron-up" : "fa-chevron-down")
                    }
                  ></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-48 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0">
                <div className="py-1 max-h-80 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
                  {dropdownData.map((item, index) => (
                    <Menu.Item key={index} as="div">
                      {({ close }) => (
                      <>
                        {item.type === "link" ? (
                          <Link
                            to={item.link}
                            className="flex items-center py-2 px-3 gap-3 text-slate-500 font-medium hover:bg-teal-500 hover:text-white transition-all duration-200"
                            onClick={close}
                          >
                            <div className="w-5 h-5 flex items-center justify-center text-base">
                              <i className={"fa-regular fa-fw " + item.icon}></i>
                            </div>
                            <div className="text-xs">{item.label}</div>
                          </Link>
                        ) : (
                          ""
                        )}
                        {item.type === "button" ? (
                          <Button
                            buttonLabel={t('logout')}
                            buttonClasses={
                              "!w-full !flex !items-center !justify-start !py-2 !px-3 !gap-3 !text-rose-500 !rounded-none !text-base !font-medium !bg-white hover:!bg-teal-500 hover:!text-white !transition-all !duration-200"
                            }
                            buttonType={"button"}
                            buttonIcon={"fa-regular fa-arrow-right-from-arc"}
                            buttonIconPosition={"left"}
                            buttonHasLink={false}
                            buttonLabelClasses={"!text-xs !font-semibold"}
                            functions={() => setenabledLogoutPopup(true)}
                          />
                        ) : (
                          ""
                        )}
                      </>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {enabledLogoutPopup && (
        <LogoutPopup
          isOpen={enabledLogoutPopup}
          setIsOpen={(val) => setenabledLogoutPopup(val)}
        />
      )}
    </>
  );
};

export default UserDropdown;
