import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";
import Search from "./Search";

const Select = ({
  xPlacement,
  dropdownData,
  dropdownClass, 
  dropdownButtonClass, 
  dropdownButtonLabelClass,
  selectedValue, 
  getSelectedValue = () => {}, 
  label,
  requiredDisplay=false,
  labelClasses,
  errorType,
  errorText,
  transitionClass,
  dropdownOptionBold,
  search = false,
  ...props
}) => {
  const [selected, setSelected] = useState((dropdownData && dropdownData.length>0)?dropdownData[0]:[]);

  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [keyword,setKeyword] = useState('');
  const [allData, setAllData] = useState([]);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement || ""],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  useEffect(() => {
    if(dropdownData.length>0){
      if(keyword){
          const filteredArray = dropdownData.filter(item => item?.label.toLowerCase().includes(keyword.toLowerCase()));
          setAllData(filteredArray.sort((a, b) => a.label.localeCompare(b.label)));
      }else{
          setAllData(dropdownData)
      }
    }        
  }, [dropdownData,keyword]);  

  useEffect(() => {    
    //if(selectedValue){
      const index = dropdownData.findIndex((item) => item._id === selectedValue);
      if(index !== -1){
        setSelected(dropdownData[index]);
      }
    //}
  }, [selectedValue,dropdownData.length])

  return (
    <>
      {label && 
        <div className={"text-sm font-medium text-slate-500 mb-1 " + (labelClasses)}>{label}
        {
          requiredDisplay &&
          <span className="text-[8px] text-red-500 ml-0.5"><i className="fa-solid fa-asterisk"></i></span>
        }
        </div>
      }
      <Listbox value={selected} onChange={(e) => {setSelected(e); getSelectedValue(e)}} as={'div'} className="relative">
        <Listbox.Button ref={setTargetElement} className="flex items-center w-full">
          <div className={"relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-slate-50 rounded-md border border-slate-200 text-slate-400 " + (dropdownButtonClass)}>
            <div className={"text-[13px] text-slate-500 font-medium whitespace-nowrap truncate " + (dropdownButtonLabelClass)}>{selected.label}</div>
            <div className="text-xs">
              <i className={"fa-light fa-fw fa-chevron-down"}></i>
            </div>
          </div>
        </Listbox.Button>
        <Transition
        //as={Fragment}
        className={"z-50 " + (transitionClass)}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        beforeEnter={() => setPopperElement(popperElRef.current)}
        afterLeave={() => setPopperElement(null)}
        ref={popperElRef}
        style={styles.popper}
        {...attributes.popper}
        >
          <Listbox.Options className={"w-40 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0 " + (dropdownClass)}>
          {search && (
            <div className="px-2 py-1">
              <Search 
                placeholder={"Search"} 
                divClasses={"!h-8"} 
                iconClasses={"!w-8 !h-8 !text-sm"} 
                classes={"!text-xs"} 
                //searchValue={keyword}
                setInput={ setKeyword } 
                onKeyDown={(e) => e.stopPropagation()}
              />
            </div>
          )}
            <div className="py-1 max-h-52 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
            {allData.map((item, index) => ( 
              <Listbox.Option
                key={index}
                className={({ active }) => "relative select-none py-2 pl-8 pr-2 text-xs leading-tight cursor-pointer transition-all duration-200 font-bold "+((item?.isPass === false)?'!text-rose-500 !font-semibold':'') + (active?'bg-slate-100 text-slate-700':'text-slate-500')}
                value={item}
                disabled={item.disabled}
              >
                {({ selected }) => (
                  <>
                    <div className={"block " + (dropdownOptionBold?(selected?" font-bold":" font-semibold"):(selected?" font-medium":" font-normal")) + (item.disabled?" opacity-30":"")}>{item.label}</div>
                    {selected &&
                      <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-teal-500 text-base">
                        <i className="fa-regular fa-fw fa-check"></i>
                      </div>
                    }
                  </>
                )}
              </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </Transition>
      </Listbox>
      {errorType && errorType === 'error' &&
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>{errorText}
        </div>
      }
      {errorType && errorType === 'warning' &&
        <div className="text-xs text-amber-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>{errorText}
        </div>
      }
      {errorType && errorType === 'success' &&
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>{errorText}
        </div>
      }
      {errorType && errorType === 'info' &&
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>{errorText}
        </div>
      }
    </>
  );
}

export default Select;