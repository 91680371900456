import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "../../components/form/Button";
import toastr from "toastr";
import Input from "../../components/form/Input";

import { postData } from "../../services/api";

import { useTranslation } from "react-i18next";

const ManageTerms = () => {
  setTitle("Succession Now | Access-Demo");
  const { i18n, t } = useTranslation();
  const [pageData, setPageData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [sub_title, setSub_title] = useState("");

  const [description, setDescription] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  useEffect(() => {
    async function loadPageData() {
      try {
        const result = await postData("page/view", {
          alias: "access-demo",
        });
        if (result.data) {
          setPageData(result.data);
        } else {
          console.log("Page Details message", result.message);
        }
      } catch (error) {
        console.log("Error page details catch", error.message);
      }
    }
    loadPageData();
  }, []);

  useEffect(() => {
    if (pageData.description) {
      setDescription(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(pageData?.description))));
    }
    setName(pageData?.name ? pageData.name : "");
    setSub_title(pageData?.sub_title ? pageData.sub_title : "");

    setId(pageData?._id);
  }, [pageData]);

  const onUpdate = async (e) => {
    setIsUpdate(true);
    const rawContentState = convertToRaw(description.getCurrentContent());
    const html = draftToHtml(rawContentState);
    try {
      let path = "";
      let payload = {};
      if (id) {
        path = "page/manage";
        payload = {
          id: id,
          name: name,
          sub_title: sub_title,
          description: html,
        };
      } else {
        path = "page/manage";
        payload = {
          alias: "access-demo",
          name: name,
          sub_title: sub_title,
          description: html,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
       <div className="relative flex justify-between items-center mb-3">
            <div className="text-2xl font-bold">{t("access_demo")}</div>
        </div>
      
      <div className="relative">
        <div className="space-y-3">
          <div className="relative">
            <Input label={t("title")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_title")} inputValue={name} setInput={setName} />
          </div>
          <div className="relative">
            <Input label={t("sub_title")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_sub_title")} inputValue={sub_title} setInput={setSub_title} />
          </div>
        </div>

        <div className="relative editor-div">
          <Editor editorState={description} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" onEditorStateChange={onEditorStateChange} />
        </div>
        <div className="py-3 border-t border-slate-200">
          <div className="flex items-center justify-end gap-4">
            <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isUpdate ? "fa-light fa-spinner fa-spin" : "fa-regular fa-floppy-disk"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onUpdate} buttonHasLink={false} buttonDisabled={isUpdate} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageTerms;
