import React from "react";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../helper/useWindowDimensions";
import { classNames } from "../../helper/classNames";

const SidebarMenuItem = ({ menuLink, menuIcon, menuIconColor, menuLabel, isMenuOpen, setIsMenuOpen, isLocked, index, isStepMenu, ...props }) => {
  const { height, width } = useWindowDimensions();

  const handleChange = () => {
    if (width < 1025){
      setIsMenuOpen(!isMenuOpen);
    }
  }

  return (
    <>
      <NavLink to={menuLink} className={({ isActive }) => classNames(
        "group flex items-center gap-1 hover:bg-teal-500 rounded overflow-hidden p-1 transition-all duration-200", 
        isActive ? " !bg-teal-500" : "", 
        isLocked ? " opacity-20 cursor-not-allowed pointer-events-none" : ""
        )}
        onClick={handleChange}  
      >
        {({ isActive }) => (
          <>
            <div className={classNames(
              "w-8 h-8 flex justify-center items-center group-hover:text-white text-lg transition-all duration-200", 
              isLocked ? "text-slate-200" : menuIconColor, 
              isActive ? " !text-white" : ""
              )}>
              {isStepMenu ? (
                <>
                  <div className={classNames(
                    "flex flex-col justify-center items-center rounded-full border border-slate-700 group-hover:border-white/40 w-8 h-8", 
                    isActive ? " !border-white/40" : ""
                    )}>
                    <div className="text-[8px] uppercase leading-none">Step</div>
                    <div className="text-[11px] leading-none">{index + 1}</div>
                  </div>
                </>
              ) : (
                <>{isLocked ? <i className={"fa-light fa-fw fa-lock"}></i> : <i className={"fa-light fa-fw " + menuIcon}></i>}</>
              )}
            </div>
            {width > 1024 ? (!isMenuOpen && <div className="text-white text-sm">{menuLabel}</div>) : (<div className="text-white text-sm">{menuLabel}</div>)}
          </>
        )}
      </NavLink>
    </>
  );
};

export default SidebarMenuItem;
